import { Box, Icon, Text, useColorModeValue } from "@chakra-ui/react";
import { IconType } from "react-icons";
import { Link, LinkProps, useMatch, useResolvedPath } from "react-router-dom";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { pageAtom, sidebarCollapseAtom } from "state";
import { getTitleFromPathname } from "utils";

interface SidebarNavLinkProps {
  icon?: IconType
}

// SidebarNavLink 自定义侧边栏NavLink
export function SidebarNavLink(props: SidebarNavLinkProps & LinkProps) {

  // 提取to和children属性
  const { icon, to, children, ...restProps } = props;

  // 判断path是否匹配
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: false });

  // active状态背景色
  const activatedBgColor = useColorModeValue("gray.100", "gray.600");

  const resetPage = useResetRecoilState(pageAtom)

  const resetSearchParams = () => {
    // TODO: resetSearchParams
    console.log("reset search params")
    resetPage()
  }

  // 默认从to中获取title
  // 若提供children则使用children
  return (
    <Link to={to} {...restProps} onClick={resetSearchParams}>
      <Box
        cursor="pointer"
        py={2}
        px={4}
        bg={match ? activatedBgColor : undefined}
        _hover={{ backgroundColor: activatedBgColor }}>
        {children
          ? children
          : <SidebarItem title={getTitleFromPathname(to as string)} icon={props.icon} />
        }
      </Box>
    </Link>
  );
}

interface SidebarItemProps {
  title?: string
  icon?: IconType
}

function SidebarItem(props: SidebarItemProps) {
  const isOpen = useRecoilValue(sidebarCollapseAtom)
  // getTitleFromPathname(to as string)

  return (
    <>
      {isOpen
        ? <Text fontSize="medium">{props.title}</Text>
        : <Icon boxSize="24px" as={props.icon} />}
    </>
  )
}
