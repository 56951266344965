import {
  SubscriptionsParams,
  SubscriptionsResp,
  TransfersParams,
  TransfersResp
} from "constant";
import { axiosInstance } from "./axios";
import { API_URI } from "./url";

export const fetchSubscriptions = (params: SubscriptionsParams) => {
  return axiosInstance.post<SubscriptionsResp>(
    API_URI.SUBSCRIPTION_SEARCH,
    params
  );
};

export const fetchTransfers = (params: TransfersParams) => {
  return axiosInstance.post<TransfersResp>(API_URI.TRANSFER_SEARCH, params);
};
