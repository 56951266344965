import {
  Box,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import { MediaType } from "constant";
import { useEffect } from "react";
import { useVideo } from "react-use";
import { useRecoilState } from "recoil";
import { fullscreenMediaAtom } from "state";

export function FullscreenMediaView() {
  const [media, setMedia] = useRecoilState(fullscreenMediaAtom);

  useEffect(() => {
    console.log("Viewing Media", media);
  });

  return (
    <Modal
      isCentered
      isOpen={media ? true : false}
      onClose={() => setMedia(undefined)}>
      <ModalOverlay />
      <ModalContent>
        {media && (
          <ModalBody p={0}>
            {media.type === MediaType.Video ? (
              <Video url={media.url} />
            ) : (
              <Image src={media.url} />
            )}
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  );
}

type VideoProps = {
  url: string;
};

function Video(props: VideoProps) {
  const [player] = useVideo(
    <video
      src={props.url}
      autoPlay
    />
  );

  return <Box>{player}</Box>;
}
