import { Link, LinkProps, Text } from "@chakra-ui/react";
import { Link as RRDLink } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { RouteURI } from "routers";
import { imeiAtom } from "state";

interface DeviceIMEIProps {
  imei: string;
  uri?: RouteURI
}

export function DeviceIMEILink(props: DeviceIMEIProps & LinkProps) {
  const { imei, uri, children, ...restProps } = props;
  const setIMEI = useSetRecoilState(imeiAtom)

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.stopPropagation()
    setIMEI(imei)
  }

  return (
    <Link
      as={RRDLink}
      to={uri ?? RouteURI.Devices}
      onClick={handleClick}
      {...restProps}
    >
      {!children ? <Text>{imei}</Text> : children}
    </Link>
  )
}