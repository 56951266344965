import { Spacer, Stack, Table, TableCaption, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { Device, DeviceStatus } from "constant";
import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import { RouteURI } from "routers";
import { devicesQuery, devicesTotalAtom } from "state/device";
import { timestampMsToDateStr } from "utils";
import { CopyButton, CustomTr, DeviceIMEILink, DeviceStatusFilter, HeaderStack, MonthFilter, Pagination, RefreshButton, TableSpinner } from "views/components";

export function DevicesPage() {

  return (
    <Stack>
      <HeaderStack>
        <RefreshButton />
        <Pagination state={devicesTotalAtom} />

        <Spacer />
        <DeviceStatusFilter />
        <MonthFilter />
      </HeaderStack>

      <DevicesTable />
    </Stack >
  )
}

function DevicesTable() {
  return (
    <Table variant="simple">
      <DevicesTableHead />

      <Suspense fallback={<TableSpinner />}>
        <DevicesTableBody />
      </Suspense>
    </Table >
  )
}

function DevicesTableHead() {
  return (
    <Thead>
      <Tr>
        <Th>#</Th>
        <Th>IMEI</Th>
        <Th>Hardware / OS</Th>
        <Th>Bundle ID / APP ID</Th>
        <Th>App Version</Th>
        <Th>Ip</Th>
        <Th>Fcm Token</Th>
        <Th>Status</Th>
        <Th>Created At</Th>
        <Th>Updated At</Th>
      </Tr>
    </Thead>
  )
}

function DevicesTableBody() {
  const devices = useRecoilValue(devicesQuery)

  return (
    <>
      {!devices
        ? <TableCaption>No Results</TableCaption>
        : <Tbody>
          {devices.map((user, index) => (
            <DevicesTableCell key={index} device={user} />
          ))}
        </Tbody>
      }
    </>
  )
}

interface DevicesTableCellProps {
  device: Device
}

function DevicesTableCell(props: DevicesTableCellProps) {
  const { device } = props
  return (
    <CustomTr>
      <Td>{device.id}</Td>
      <Td>
        <DeviceIMEILink imei={device.imei} uri={RouteURI.Users} />
      </Td>
      <Td>
        <Stack spacing={0}>
          <Text>{device.hardwareModel}</Text>
          <Text>{device.osVersion}</Text>
        </Stack>
      </Td>
      <Td>
        <Stack spacing={0}>
          <Text>{device.bundleId}</Text>
          <Text opacity="0.4">{device.appId}</Text>
        </Stack>
      </Td>
      <Td><Text>{device.appVersion} / ({device.appBuild})</Text></Td>
      <Td>{device.ip}</Td>
      <Td>
        {device.fcmToken
          ? <CopyButton value={device.fcmToken} />
          : "--"}
      </Td>
      <Td>{DeviceStatus[device.status]}</Td>
      <Td>{timestampMsToDateStr(device.createdAt)}</Td>
      <Td>{timestampMsToDateStr(device.updatedAt)}</Td>
    </CustomTr>
  )
}