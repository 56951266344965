import { fetchReports } from "apis";
import { Report, ReportsResp } from "constant";
import { selector } from "recoil";
import { setRecoil } from "recoil-nexus";
import { getErrorMessage } from "utils";
import { statuses } from "views/components";
import { genders } from "views/layouts";
import {
  errorAtom,
  nextStatusAtom,
  pageAtom,
  pageSizeAtom,
  tabAtom,
  timestampAtom
} from "./common";

// Report
export const reportsRespQuery = selector<ReportsResp | undefined>({
  key: "reports/resp",
  get: async ({ get }) => {
    // request id for refresh
    get(timestampAtom);

    const page = get(pageAtom);
    const pageSize = get(pageSizeAtom);
    const gender = genders[get(tabAtom)];
    const status = statuses[get(nextStatusAtom)];

    try {
      const resp = await fetchReports({
        page,
        pageSize,
        gender: [gender],
        status: [status],
      });
      return resp.data;
    } catch (error) {
      setRecoil(errorAtom, getErrorMessage(error));
      return undefined;
    }
  },
});

export const reportsQuery = selector<Report[] | undefined>({
  key: "reports",
  get: async ({ get }) => {
    const resp = get(reportsRespQuery);

    if (resp?.error) {
      setRecoil(errorAtom, resp.error);
      return undefined;
    }

    if (resp?.data) {
      return resp.data;
    }

    return undefined;
  },
});

export const reportsTotalQuery = selector({
  key: "reports/total",
  get: ({ get }) => {
    const resp = get(reportsRespQuery);
    return resp?.page?.total ?? 0;
  },
});
