import {
  Tab,
  TabList,
  TabPanels,
  TabPanelsProps,
  Tabs,
} from "@chakra-ui/react";
import { useRecoilState } from "recoil";
import { tabAtom } from "state";

interface CustomTabProps {
  tabs: string[];
  onReset?: () => void;
}

export function CustomTab(props: CustomTabProps & TabPanelsProps) {
  const { tabs, onReset, children, ...restProps } = props;

  const [currentTab, setTab] = useRecoilState(tabAtom);

  return (
    <Tabs
      isLazy
      index={currentTab}
      onChange={setTab}
      variant="soft-rounded">
      <TabList>
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            onClick={onReset}>
            {tab.replace(/_/g, " ")}
          </Tab>
        ))}
      </TabList>
      <TabPanels {...restProps}>{children}</TabPanels>
    </Tabs>
  );
}
