import { Box, Select } from "@chakra-ui/react";
import { number } from "@recoiljs/refine";
import { AutoStatus } from "constant";
import { atom, selector, useRecoilState } from "recoil";
import { syncEffect } from "recoil-sync";

export const autoStatusSelectAtom = atom<number>({
  key: "auto_status",
  default: 0,
  effects: [syncEffect({ refine: number() })],
});

enum AutoStatusOption {
  All,
  "Under Review",
  Reviewed,
}
const autoStatuses = [
  AutoStatusOption.All,
  AutoStatusOption["Under Review"],
  AutoStatusOption.Reviewed,
];

export const autoStatusQuery = selector<number[]>({
  key: "params/autoStatus",
  get: ({ get }) => {
    const selectStatus = get(autoStatusSelectAtom);
    switch (selectStatus) {
      case AutoStatusOption.All:
        return [
          AutoStatus.Undetermined,
          AutoStatus.UnderReview,
          AutoStatus.Reviewed,
        ];
      case AutoStatusOption["Under Review"]:
        return [AutoStatus.UnderReview];
      case AutoStatusOption.Reviewed:
        return [AutoStatus.Undetermined, AutoStatus.Reviewed];
      default:
        return [
          AutoStatus.Undetermined,
          AutoStatus.UnderReview,
          AutoStatus.Reviewed,
        ];
    }
  },
});

export function AutoStatusSelect() {
  const [autoStatusSelect, setAutoStatusSelect] =
    useRecoilState(autoStatusSelectAtom);

  return (
    <Box>
      <Select
        value={autoStatusSelect}
        onChange={(e) => setAutoStatusSelect(+e.target.value)}>
        {autoStatuses.map((status) => (
          <option
            key={status}
            value={status}>
            {AutoStatusOption[status]}
          </option>
        ))}
      </Select>
    </Box>
  );
}
