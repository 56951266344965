import { fetchDescriptions } from "apis";
import {
  BioStatus,
  ContentToSubmit,
  Description,
  DescriptionsResp,
  ReviewStatus,
} from "constant";
import { atom, selector } from "recoil";
import { setRecoil } from "recoil-nexus";
import { getErrorMessage } from "utils";
import { autoStatusQuery, statuses } from "views/components";
import { genders } from "views/layouts";
import {
  errorAtom,
  nextStatusAtom,
  pageAtom,
  pageSizeAtom,
  tabAtom,
  timestampAtom,
} from "./common";

// BIO
export const descriptionsRespQuery = selector<DescriptionsResp | undefined>({
  key: "descriptions/resp",
  get: async ({ get }) => {
    // request id for refresh
    get(timestampAtom);

    const page = get(pageAtom);
    const pageSize = get(pageSizeAtom);
    const gender = genders[get(tabAtom)];
    const status = statuses[get(nextStatusAtom)];
    const autoStatus = get(autoStatusQuery);

    try {
      const resp = await fetchDescriptions({
        page,
        pageSize,
        gender: [gender],
        status: [
          ReviewStatus.Approved,
          ReviewStatus.Pending,
          ReviewStatus.Rejected,
        ],
        desStatus: [status],
        autoStatus,
      });
      return resp.data;
    } catch (error) {
      setRecoil(errorAtom, getErrorMessage(error));
      return undefined;
    }
  },
});

export const descriptionsAtom = selector<Description[] | undefined>({
  key: "descriptions",
  get: async ({ get }) => {
    const resp = get(descriptionsRespQuery);

    if (resp?.error) {
      setRecoil(errorAtom, resp.error);
      return undefined;
    }

    if (resp?.data) {
      return resp.data;
    }

    return undefined;
  },
});

export const descriptionsTotalQuery = selector({
  key: "descriptions/total",
  get: ({ get }) => {
    const resp = get(descriptionsRespQuery);
    return resp?.page?.total ?? 0;
  },
});

export const selectedDescriptionIdsAtom = atom<number[]>({
  key: "descriptions/selected",
  default: [],
});

export const descriptionsToSubmitQuery = selector<ContentToSubmit | undefined>({
  key: "descriptions/submit",
  get: ({ get }) => {
    const descriptions = get(descriptionsAtom);
    const selects = get(selectedDescriptionIdsAtom);

    if (!descriptions) return;

    let contentToSubmit: ContentToSubmit = {
      adoptIds: [],
      refuseIds: [],
    };

    descriptions.forEach((item) => {
      if (selects.includes(item.id)) {
        // 标记为reject
        contentToSubmit.refuseIds.push(item.id);
      } else if (item.status === BioStatus.Pending) {
        // 新内容，待审核，默认通过
        contentToSubmit.adoptIds.push(item.id);
      }
    });

    return contentToSubmit;
  },
});
