import { Box, Select } from "@chakra-ui/react";
import React from "react";

export function FilterSelector(props: FilterSelectorProps) {
  return (
    // Box 包裹，宽度会适应内容的宽度
    <Box>
      <Select
        placeholder={props.placeholder}
        defaultValue={props.defaultValue}
        onChange={props.onChange}>
        {props.options.map((opt, index) => (
          <option key={index} value={opt.value}>{opt.title}</option>
        ))}
      </Select>
    </Box>
  );
}

interface FilterSelectorProps {
  options: FilterOption[];
  placeholder?: string | undefined;
  defaultValue?: string | number | readonly string[] | undefined;
  onChange?: React.ChangeEventHandler<HTMLSelectElement> | undefined;
}

export interface FilterOption {
  title: string;
  value: number;
}
