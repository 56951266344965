import {
  Heading,
  HStack,
  Image, useColorModeValue
} from "@chakra-ui/react";
import { useRecoilValue } from "recoil";
import { sidebarCollapseAtom } from "state";
import logo from "../../../assets/images/of_icon.png";

export function Logo() {
  const bgColor = useColorModeValue("gray.50", "gray.700");
  const isOpen = useRecoilValue(sidebarCollapseAtom)

  return (
    <HStack px={4} position="sticky" h="52px" bg={bgColor} zIndex={2}>
      <Image w="24px" src={logo} borderRadius={4} />
      {isOpen && <Heading size="md">{process.env.REACT_APP_NAME ?? "App"}</Heading>}
    </HStack>
  );
}

export function LogoImage() {
  return <Image w="80px" src={logo} borderRadius={40} />;
}
