import { Spinner, Wrap, WrapItem } from "@chakra-ui/react";
import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import { selectedVideoIdsAtom, videosAtom } from "state";
import { NextMediaView } from "views/components";

export function VideosPage() {
  return (
    <Suspense fallback={<Spinner />}>
      <VideosGrid />
    </Suspense>
  );
}

function VideosGrid() {
  const videos = useRecoilValue(videosAtom);

  return (
    <Wrap>
      {videos &&
        videos.map((video) => (
          <WrapItem key={video.media.id}>
            <NextMediaView
              info={video}
              selectedState={selectedVideoIdsAtom}
            />
          </WrapItem>
        ))}
    </Wrap>
  );
}
