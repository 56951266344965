import { Text } from "@chakra-ui/react";
import { useRecoilValue } from "recoil";
import { pageAtom, pageSizeAtom } from "state";

interface PaginationTotalViewProps {
  total: number
}

export function PaginationTotalView(props: PaginationTotalViewProps) {
  const { total } = props
  const page = useRecoilValue(pageAtom);
  const pageSize = useRecoilValue(pageSizeAtom);

  return (
    <Text>{(page - 1) * pageSize + 1} - {Math.min(page * pageSize, total)} of {total}</Text>
  );
}

