import {

  Table, Tbody, Th, Thead, Tr
} from "@chakra-ui/react";
import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import { membersAtom } from "state";
import { TableSpinner } from "../spinner";
import { MembersTableCell } from "./MembersTableCell";

export const MembersTable = () => {
  return (
    <Table variant="simple">
      <MembersTableHead />

      <Suspense fallback={<TableSpinner />}>
        <MembersTableBody />
      </Suspense>
    </Table>
  );
};

function MembersTableHead() {
  return (
    <Thead>
      <Tr>
        <Th>#</Th>
        <Th>Name</Th>
        <Th>Email</Th>
        <Th>Status</Th>
        <Th>Created At</Th>
        <Th>Updated At</Th>
        <Th></Th>
      </Tr>
    </Thead>
  )
}

function MembersTableBody() {
  const members = useRecoilValue(membersAtom);

  return (
    <Tbody>
      {members.map(member => <MembersTableCell key={member.id} member={member} />)}
    </Tbody>
  )
}
