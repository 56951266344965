import { Wrap, WrapItem } from "@chakra-ui/react";
import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import { selectedPhotoIdsAtom, userPhotosQuery } from "state";
import { NextMediaView } from "../NextMediaView";

export function UserPhotos() {
  const photos = useRecoilValue(userPhotosQuery);

  return (
    <Wrap>
      {photos.map((media) => (
        <WrapItem key={media.media.id}>
          <NextMediaView
            info={media}
            selectedState={selectedPhotoIdsAtom}
          />
        </WrapItem>
      ))}
    </Wrap>
  );
}

export function UserPhotosWrapper() {
  return (
    <>
      <Suspense fallback={<></>}>
        <UserPhotos />
      </Suspense>
    </>
  );
}
