export enum RouteURI {
  // Common
  NotFound = "*",
  Home = "/",
  Login = "/login",
  Dashboard = "/dashboard",

  // Review
  Avatars = "/avatars",
  Photos = "/photos",
  Videos = "/videos",
  Bios = "/bios",
  Descriptions = "/descriptions",
  Reports = "/reports",

  // Manage
  Users = "/users",
  Devices = "/devices",

  // Purchase
  Subscriptions = "/subscriptions",
  Transfers = "/transfers",

  // Setting
  Members = "/members",
}
