import { Avatar, Spacer, Stack, Table, TableCaption, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { Gender, User } from "constant";
import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import { usersQuery, usersTotalAtom } from "state";
import { timestampMsToAge, timestampMsToDateStr } from "utils";
import { CustomTr, getUserIdentity, HeaderStack, IdentityTypeTag, MonthFilter, Pagination, RefreshButton, TableSpinner, UserStatusFilter, UserStatusTag, UserUUIDLink } from "views/components";


export function UsersPage() {

  return (
    <Stack>
      <HeaderStack>
        <RefreshButton />
        <Pagination state={usersTotalAtom} />

        <Spacer />
        <UserStatusFilter />
        <MonthFilter />
      </HeaderStack>

      <UsersTable />
    </Stack >
  )
}

function UsersTable() {
  return (

    <Table variant="simple">
      <UsersTableHead />
      <Suspense fallback={<TableSpinner />}>
        <UsersTableBody />
      </Suspense>
    </Table >
  )
}

function UsersTableHead() {
  return (
    <Thead>
      <Tr>
        <Th>UUID</Th>
        <Th>Identity</Th>
        <Th>Avatar</Th>
        <Th>Name</Th>
        <Th>Gender</Th>
        <Th>Age</Th>
        <Th>Status</Th>
        <Th>Created At</Th>
        <Th>Updated At</Th>
      </Tr>
    </Thead>
  )
}

function UsersTableBody() {
  const users = useRecoilValue(usersQuery)

  return (
    <>
      {!users
        ? <TableCaption>No Results</TableCaption>
        : <Tbody>
          {users.map((user, index) => (
            <UsersTableCell key={index} user={user} />
          ))}</Tbody>
      }
    </>
  )
}

interface UsersTableCellProps {
  user: User
}

function UsersTableCell(props: UsersTableCellProps) {
  const { user } = props
  return (
    <CustomTr>
      <Td><UserUUIDLink uuid={user.uuid} /></Td>
      <Td>
        {user.likeIdentity &&
          <IdentityTypeTag identity={getUserIdentity(user.likeIdentity)} />
        }
      </Td>
      <Td>
        <UserUUIDLink uuid={user.uuid}>
          <Avatar name={user.name} src={user.avatar?.url} />
        </UserUUIDLink>
      </Td>
      <Td>
        <UserUUIDLink uuid={user.uuid}>
          <Text>{user.name ?? "--"}</Text>
        </UserUUIDLink>
      </Td>
      <Td>{user.gender ? Gender[user.gender].replace(/_/g, " ") : "--"}</Td>
      <Td>{user.birthday ? timestampMsToAge(user.birthday) : "-"}</Td>
      <Td><UserStatusTag status={user.status} /></Td>
      <Td>{timestampMsToDateStr(user.createdAt)}</Td>
      <Td>{timestampMsToDateStr(user.updatedAt)}</Td>
    </CustomTr>
  )
}

