import { AspectRatio, HStack, Icon, Image, Spacer, Spinner, Stack, useColorModeValue, Wrap, WrapItem } from "@chakra-ui/react"
import { UserBasicInfo } from "constant"
import _ from "lodash"
import { Suspense } from "react"
import { BsCheckCircle } from "react-icons/bs"
import { useRecoilState, useRecoilValue } from "recoil"
import { avatarsAtom, selectedAvatarIdsAtom } from "state"
import { Username } from "views/components"

export function AvatarsPage() {
  return (
    <Suspense fallback={<Spinner />}>
      <AvatarsList />
    </Suspense>
  )
}

function AvatarsList() {
  const avatars = useRecoilValue(avatarsAtom)

  return (
    <Wrap>
      {avatars && avatars.map((userInfo, index) => (
        <WrapItem key={index}>
          <AvatarView userInfo={userInfo} />
        </WrapItem>
      ))}
    </Wrap>
  )
}

interface AvatarViewProps {
  userInfo: UserBasicInfo
}

function AvatarView(props: AvatarViewProps) {
  const { userInfo } = props

  const hoverBg = useColorModeValue("gray.100", "gray.700")

  const [selects, select] = useRecoilState(selectedAvatarIdsAtom)
  const isSelected = props.userInfo.avatar && selects.includes(props.userInfo.avatar.id)

  const handleClick = () => {
    console.log(userInfo);

    const { avatar } = userInfo

    if (avatar) {
      select(curr => {
        const _selects = _.cloneDeep(curr)

        if (isSelected) {
          _.remove(_selects, id => id === avatar.id)
        } else {
          _selects.push(avatar.id)
        }

        return _selects
      })
    }
  }

  return (
    <Stack
      w="188px"
      spacing={0}
      cursor="pointer"
      border="1px"
      borderColor={hoverBg}
      _hover={{ bg: hoverBg }}
      onClick={handleClick}
    >
      <AspectRatio ratio={1 / 1}>
        <Image
          src={userInfo.avatar?.url}
          alt={userInfo.name + ""}
        />
      </AspectRatio>
      <HStack p={2}>
        <Username userInfo={userInfo} />
        <Spacer />

        {isSelected && <Icon w="24px" h="24px" as={BsCheckCircle} color="red" />}
      </HStack>
    </Stack>
  )
}