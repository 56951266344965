import { Box, Tag, Tooltip } from "@chakra-ui/react";

export function StatusTag(props: StatusTagProps) {
  // 状态为删除时，内容展示为贯穿线
  const as = props.isDeleted ? "s" : undefined;

  // Tag中仅展示首字母
  const str = props.title.substring(0, 1);

  return (
    <Box>
      <Tooltip label={props.title}>
        <Tag colorScheme={props.color} as={as}>
          {str}
        </Tag>
      </Tooltip>
    </Box>
  );
}

interface StatusTagProps {
  title: string;
  color?: string;
  isDeleted?: boolean;
}
