import axios from "axios";
import { StatusCode } from "constant";
import { auth } from "../firebase";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
  timeout: 10000, // ms
});

axiosInstance.interceptors.request.use(async (request) => {
  // 从firebase获取
  const idToken = await auth.currentUser?.getIdToken();

  // 为headers追加idToken
  request.headers.set("authorization", idToken);

  return request;
});

axiosInstance.interceptors.response.use(async (response) => {
  // 全局拦截错误码
  const { code }: { code: StatusCode } = response.data;

  switch (code) {
    case StatusCode.Ok:
      return response;
    case StatusCode.MissingPermission:
      // 登出
      auth.signOut();
      throw new Error("Missing Permission");
    default:
      // TODO: 拦截全局错误
      return response;
  }
});
