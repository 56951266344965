import { Box, Select } from "@chakra-ui/react";
import { editUser } from "apis";
import { Gender, UserToEdit } from "constant";
import dayjs from "dayjs";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { errorAtom, timestampAtom, userDetailsQuery } from "state";
import { getErrorMessage } from "utils";
import { genders } from "views/layouts";

export function GenderEditor() {
  const user = useRecoilValue(userDetailsQuery);
  const setError = useSetRecoilState(errorAtom);
  const setTimestamp = useSetRecoilState(timestampAtom);

  const submit = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (!user) {
      return;
    }

    const userToEdit: UserToEdit = {
      uuid: user.uuid,
      gender: parseInt(e.target.value) as Gender,
    };

    try {
      const resp = await editUser(userToEdit);

      if (resp.data.error) {
        setError(resp.data.error);
      } else {
        setTimestamp(dayjs().valueOf());
      }
    } catch (error) {
      setError(getErrorMessage(error));
    }
  };

  return (
    <Box>
      <Select
        defaultValue={user?.gender}
        onChange={submit}>
        {genders.map((gender, index) => (
          <option
            key={index}
            value={gender.toString()}>
            {Gender[gender]}
          </option>
        ))}
      </Select>
    </Box>
  );
}
