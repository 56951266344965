import { HStack, Spacer, Stack, Tag, Text } from "@chakra-ui/react";
import { BioInfo, BioStatus } from "constant";
import dayjs from "dayjs";
import _ from "lodash";
import { useRecoilState } from "recoil";
import { selectedBioIdsAtom } from "state";

export function UserBioView({ bio }: UserBioViewProps) {
  const [selectedIds, selectId] = useRecoilState(selectedBioIdsAtom);
  const isSelected = selectedIds.includes(bio.id);

  const handleSelect = () => {
    selectId((curr) => {
      const ids = _.cloneDeep(curr);

      isSelected ? _.remove(ids, (id) => id === bio.id) : ids.push(bio.id);
      return ids;
    });
  };

  return (
    <Stack w="100%">
      <Text
        bg={isSelected ? "red" : undefined}
        cursor="pointer"
        onClick={handleSelect}>
        {bio.content}
      </Text>

      <HStack>
        <Tag>{BioStatus[bio.status]}</Tag>
        <Spacer />
        <Text opacity={0.4}>{dayjs(bio.createdAt).fromNow()}</Text>
      </HStack>
    </Stack>
  );
}
interface UserBioViewProps {
  bio: BioInfo;
}
