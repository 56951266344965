import { Text } from "@chakra-ui/react";
import { useRecoilValue } from "recoil";
import { sidebarCollapseAtom } from "state";

interface SidebarCapitalProps {
  title: string;
}
export function SidebarCapital(props: SidebarCapitalProps) {

  const isOpen = useRecoilValue(sidebarCollapseAtom);

  return (
    <>
      {isOpen &&
        <Text px={4} pt={4} fontSize={12} fontWeight="semibold" opacity="0.4">
          {props.title.toUpperCase()}
        </Text>}
    </>
  );
}
