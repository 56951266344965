import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { RequireAuth } from "routers";
import { MainLayout, ReviewLayout } from "views/layouts";
import { BiosPage, Dashboard, DescriptionsPage, DevicesPage, LoginPage, MembersPage, NotFoundPage, PhotosPage, ReportsPage, SubscriptionsPage, TransfersPage, UserDetailsPage, UsersPage, VideosPage } from "views/pages";
import { AvatarsPage } from "views/pages/AvatarsPage";
import { RouteURI } from "./routes";

export function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes >
        {/* PRIVATE */}
        <Route
          path={RouteURI.Home}
          element={(
            <RequireAuth>
              <MainLayout />
            </RequireAuth>)}>
          {/* 重定向到Dashboard页面 */}
          <Route index element={<Navigate to={RouteURI.Dashboard} />} />
          <Route path={RouteURI.Dashboard} element={<Dashboard />} />

          <Route element={<ReviewLayout />}>
            <Route path={RouteURI.Avatars} element={<AvatarsPage />} />
            <Route path={RouteURI.Photos} element={<PhotosPage />} />
            <Route path={RouteURI.Videos} element={<VideosPage />} />
            <Route path={RouteURI.Bios} element={<BiosPage />} />
            <Route path={RouteURI.Descriptions} element={<DescriptionsPage />} />
            <Route path={RouteURI.Reports} element={<ReportsPage />} />
          </Route>

          <Route path={RouteURI.Users}>
            <Route index element={<UsersPage />} />
            <Route path=":uuid" element={<UserDetailsPage />} />
          </Route>
          <Route path={RouteURI.Devices} element={<DevicesPage />} />
          <Route path={RouteURI.Subscriptions} element={<SubscriptionsPage />} />
          <Route path={RouteURI.Transfers} element={<TransfersPage />} />
          <Route path={RouteURI.Members} element={<MembersPage />} />
        </Route>

        {/* PUBLIC */}
        <Route path={RouteURI.Login} element={<LoginPage />} />
        <Route path={RouteURI.NotFound} element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  )
}