import {
  AvatarsParams,
  AvatarsResp,
  BiosParams,
  BiosResp,
  ContentToSubmit,
  DescriptionsParams,
  DescriptionsResp,
  MediaInfosResp,
  MediaParams,
  MediaType,
  NullResp,
  PhotosParams,
  ProfilesParams,
  ProfilesResp,
  ReportsParams,
  ReportsResp,
} from "constant";
import { axiosInstance } from "./axios";
import { API_URI } from "./url";

export const fetchAvatars = (params: AvatarsParams) => {
  return axiosInstance.post<AvatarsResp>(API_URI.AVATAR_SEARCH, params);
};

export const submitAvatars = (params: ContentToSubmit) => {
  return axiosInstance.post<NullResp>(API_URI.AVATAR_SUBMIT, params);
};

export const fetchMedia = (
  params: MediaParams = {
    type: [MediaType.Image, MediaType.Video],
    gender: [],
    page: 1,
    pageSize: 30,
  }
) => {
  return axiosInstance.post<MediaInfosResp>(API_URI.MEDIA_SEARCH, params);
};

export const fetchPhotos = (
  params: PhotosParams = {
    type: [MediaType.Image],
    gender: [],
    page: 1,
    pageSize: 30,
  }
) => {
  return fetchMedia(params);
};

export const fetchVideos = (
  params: PhotosParams = {
    type: [MediaType.Video],
    gender: [],
    page: 1,
    pageSize: 30,
  }
) => {
  return fetchMedia(params);
};

export const submitMedia = (params: ContentToSubmit) => {
  return axiosInstance.post<NullResp>(API_URI.MEDIA_SUBMIT, params);
};

export const fetchBios = (params: BiosParams) => {
  return axiosInstance.post<BiosResp>(API_URI.BIO_SEARCH, params);
};

export const submitBios = (params: ContentToSubmit) => {
  return axiosInstance.post<NullResp>(API_URI.BIO_SUBMIT, params);
};

export const fetchDescriptions = (params: DescriptionsParams) => {
  return axiosInstance.post<DescriptionsResp>(
    API_URI.DESCRIPTION_SEARCH,
    params
  );
};

export const submitDescriptions = (params: ContentToSubmit) => {
  return axiosInstance.post<NullResp>(API_URI.DESCRIPTION_SUBMIT, params);
};

export const fetchReports = (params: ReportsParams) => {
  return axiosInstance.post<ReportsResp>(API_URI.REPORT_SEARCH, params);
};

// TODO: 废弃
export const fetchProfiles = (params: ProfilesParams) => {
  return axiosInstance.post<ProfilesResp>(API_URI.MEDIA_SEARCH, params);
};

export const submitProfiles = (params: ContentToSubmit) => {
  return axiosInstance.post<NullResp>(API_URI.MEDIA_SUBMIT, params);
};
