import { Button, FormControl, FormLabel, HStack, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, useDisclosure } from "@chakra-ui/react";
import { createMember } from "apis";
import { MemberToCreate } from "constant";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";
import { FiPlus } from "react-icons/fi";
import { useSetRecoilState } from "recoil";
import { errorAtom, timestampAtom } from "state";
import { getErrorMessage } from "utils";

const defaultValues: MemberToCreate = { name: "", email: "" }

export const AddMemberButton = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { register, handleSubmit, formState } = useForm({ defaultValues })

  const setTimestamp = useSetRecoilState(timestampAtom)
  const setError = useSetRecoilState(errorAtom)

  const submit = async (data: MemberToCreate) => {
    try {
      // 提交接口
      const resp = await createMember(data)

      if (resp.data.error) {
        setError(resp.data.error)
      } else {
        // 关闭modal
        onClose()

        // 刷新 members 列表
        setTimestamp(dayjs().valueOf())
      }

    } catch (error) {
      setError(getErrorMessage(error))
    }
  };

  return (
    <>
      <Button leftIcon={<FiPlus />} onClick={onOpen}>
        Add
      </Button>

      <Modal
        isCentered
        // initialFocusRef={initialRef}
        onClose={onClose}
        isOpen={isOpen}
        motionPreset="slideInBottom"
        scrollBehavior="inside">

        <ModalOverlay />

        <ModalContent>
          <ModalHeader>Create Member</ModalHeader>


          <form onSubmit={handleSubmit(submit)}>
            <ModalBody>
              <Stack spacing={4}>

                <FormControl isRequired>
                  <FormLabel>Name</FormLabel>
                  <Input placeholder="Name" {...register("name")} />
                </FormControl>

                <FormControl isRequired>
                  <FormLabel>Email</FormLabel>
                  <Input placeholder="Email" {...register("email")} type="email" />
                </FormControl>

              </Stack>
            </ModalBody>

            <ModalFooter>
              <HStack>
                <Button variant='ghost' onClick={onClose}>
                  Close
                </Button>

                <Button isLoading={formState.isSubmitting} type="submit">
                  Create
                </Button>
              </HStack>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};


