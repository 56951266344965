import {
  PageParams,
  Resp,
  TimeRangeParams,
  Timestamp,
  Timestamps
} from "./common";
import { UserBasicInfo } from "./user";

// TODO：
// 订阅
// platform to purchaseFrom
// productTypes 是数组吗？
// 转移记录中将，from和to包裹一下
// 转移记录增加uuid搜索，可以搜到from和to的两种数据

// 用户信息
// userName 和 name 选择一个统一一下
// user info 中，给uuid的地方都给name和avatar

export interface SubscriptionsParams extends TimeRangeParams, PageParams {
  id?: number;
  uuid?: string;

  purchaseFrom?: PurchaseFrom;

  originalTransactionId?: string;
  transactionId?: string;

  productIds?: string[];
  productTypes?: ProductType[];

  expiresDateEnd?: Timestamp;
  expiresDateStart?: Timestamp;

  purchaseDateEnd?: Timestamp;
  purchaseDateStart?: Timestamp;

  offerCode?: string;
}

export enum PurchaseFrom {
  Apple = 1,
  Google = 2,
  Console = 5,
}

export enum ProductType {
  Subscription = 1,
}

export type SubscriptionsResp = Resp<Subscription[]>;

export interface Subscription extends UserBasicInfo, Timestamps {
  id: number;

  purchaseFrom: PurchaseFrom;
  productId: string;
  productType: ProductType;

  originalPurchaseDate: Timestamp;
  originalTransactionId: string;
  purchaseDate: Timestamp;
  transactionId: string;

  expiresDate: Timestamp;
  offerCode: string;
}

export interface TransfersParams extends TimeRangeParams, PageParams {
  originalTransactionId?: string;
  uuid?: string; // 包含from和to
}

export type TransfersResp = Resp<Transfer[]>;

export interface Transfer extends Timestamps {
  id: number;
  originalTransactionId: string;
  fromUser?: UserBasicInfo;
  toUser?: UserBasicInfo;
}
