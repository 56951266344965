import { AspectRatio, Divider, HStack, Image, SimpleGrid, Spinner, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import { Report } from "constant";
import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import { reportsQuery } from "state";
import { ReportsView, UserUUIDLink } from "views/components";

export function ReportsPage() {
  return (
    <Suspense fallback={<Spinner />}>
      <ReportsGrid />
    </Suspense>
  )
}

function ReportsGrid() {

  const reports = useRecoilValue(reportsQuery)

  return (
    <SimpleGrid minChildWidth="600px" spacing={2}>
      {reports && reports.map((report, index) => (
        <ReportCard key={index} report={report} />
      ))}
    </SimpleGrid>
  )
}

interface ReportCardProps {
  report: Report
}

function ReportCard(props: ReportCardProps) {
  const { report } = props

  const borderColor = useColorModeValue("gray.100", "gray.700")

  return (
    <HStack
      alignItems="flex-start"
      border="1px"
      borderColor={borderColor}
      spacing={0}
      cursor="pointer">
      <Stack spacing={0} _hover={{ bg: borderColor }}>
        {report.avatar &&
          <UserUUIDLink uuid={report.uuid} isExternal>
            <AspectRatio ratio={10 / 16} w="156px">
              <Image src={report.avatar.url} alt={report.name} />
            </AspectRatio>
          </UserUUIDLink>}
        <HStack p={2}>
          <UserUUIDLink uuid={report.uuid} isExternal>
            <Text>{report.name}</Text>
          </UserUUIDLink>
        </HStack>
      </Stack>

      <Divider orientation="vertical" color={borderColor} />

      <ReportsView reports={report.reports} />
    </HStack >
  )
}

