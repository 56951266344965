import {
  Box,
  IconButton,
  Tooltip,
  useClipboard,
  useToast,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { BiCopyAlt } from "react-icons/bi";

export function CopyButton(props: CopyButtonProps) {
  const { hasCopied, onCopy } = useClipboard(props.value);
  const toast = useToast();

  useEffect(() => {
    if (hasCopied) {
      toast({
        title: "Copied",
        status: "success",
      });
    }
  }, [hasCopied, toast]);

  return (
    <Box>
      <Tooltip label={props.value}>
        <IconButton
          aria-label={props.value}
          icon={<BiCopyAlt />}
          colorScheme={"purple"}
          variant="ghost"
          onClick={onCopy}
        />
      </Tooltip>
    </Box>
  );
}

interface CopyButtonProps {
  value: string;
}
