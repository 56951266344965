import { Wrap, WrapItem } from "@chakra-ui/react";
import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import { selectedVideoIdsAtom, userVideosQuery } from "state";
import { NextMediaView } from "../NextMediaView";

function UserVideos() {
  const videos = useRecoilValue(userVideosQuery);

  return (
    <Wrap>
      {videos.map((media) => (
        <WrapItem key={media.media.id}>
          <NextMediaView
            info={media}
            selectedState={selectedVideoIdsAtom}
          />
        </WrapItem>
      ))}
    </Wrap>
  );
}

export function UserVideosWrapper() {
  return (
    <>
      <Suspense fallback={<></>}>
        <UserVideos />
      </Suspense>
    </>
  );
}
