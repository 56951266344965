import {
  IconButton, Menu,
  MenuButton, MenuList
} from "@chakra-ui/react";
import { FiMoreVertical } from "react-icons/fi";
import { DeleteMemberMenuItem, EditMemberMenuItem } from "views/components";

interface ActionButtonProps {
  id: number;
}

export const ActionButton = (props: ActionButtonProps) => {
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label='Options'
        icon={<FiMoreVertical />}
        variant='outline' />

      <MenuList>
        <EditMemberMenuItem id={props.id} />
        <DeleteMemberMenuItem id={props.id} />
      </MenuList>
    </Menu>
  );
};
