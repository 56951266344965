import { UserStatus } from "constant";
import { FilterOption } from "./FilterSelector";
import { StatusFilter } from "./StatusFilter";

const statuses = [
  UserStatus.New,
  UserStatus.Pending,
  UserStatus.Rejected,
  UserStatus.Approved,
  UserStatus.Banned,
  UserStatus.Deleted,
]

export function UserStatusFilter() {
  const options = statuses.map((status) => {
    const option: FilterOption = {
      title: UserStatus[status],
      value: status,
    };
    return option;
  });

  return <StatusFilter options={options} />;
}
