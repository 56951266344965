import { Td } from "@chakra-ui/react";
import { Member, MemberStatus } from "constant";
import { getDateFromTimestamp } from "utils";
import { CustomTr } from "views/components";
import { ActionButton } from "./ActionButton";

interface MembersTableCellProps {
  member: Member;
}

export const MembersTableCell = (props: MembersTableCellProps) => {
  const { member } = props;

  return (
    <CustomTr key={member.id}>
      <Td>{member.id}</Td>
      <Td>{member.name}</Td>
      <Td>{member.email}</Td>
      <Td>{MemberStatus[member.status]}</Td>
      <Td>{getDateFromTimestamp(member.createdAt)}</Td>
      <Td>{getDateFromTimestamp(member.updatedAt)}</Td>
      <Td>
        <ActionButton id={member.id} />
      </Td>
    </CustomTr>
  );
};
