import { TimeRangeParams } from "constant";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import relativeTime from "dayjs/plugin/relativeTime";

// use plugin
dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);

// timestampMsToDate 将毫秒时间戳转换为日期字符串
// 格式MMM DD, YYYY HH:mm:ss
export const getDateFromTimestamp = (timestampMs: number): string => {
  return dayjs(timestampMs).format("MMM DD, YYYY HH:mm:ss");
};

// 时间戳转年龄
export const timestampMsToAge = (timestampMs: number): number => {
  return dayjs().diff(dayjs(timestampMs), "year");
};

export const getTimeRangeParams = (yyyymm: string) => {
  const date = dayjs(yyyymm, "YYYYMM");

  // 不要指定day(1), 会有bug
  const timeRangeParams: TimeRangeParams = {
    createdStart: date.valueOf(),
    createdEnd: date.month(date.month() + 1).valueOf(),
  };

  return timeRangeParams;
};

// timestampMsToDateStr 将毫秒时间戳转换为日期字符串
// 格式MMM DD, YYYY HH:mm:ss
export const timestampMsToDateStr = (timestampMs: number): string => {
  return dayjs(timestampMs).format("MMM DD, YYYY HH:mm:ss");
};
