import { DevicesParams, DevicesResp, NullResp } from "constant";
import { axiosInstance } from "./axios";
import { API_URI } from "./url";

export const fetchDevices = (params: DevicesParams) => {
  return axiosInstance.post<DevicesResp>(API_URI.DEVICE_SEARCH, params);
};

export const toggleDeviceStatus = (id: number) => {
  return axiosInstance.post<NullResp>(API_URI.DEVICE_SWITCH_DISABLE, { id });
};
