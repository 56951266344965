import { Avatar, HStack, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import { ReportRecord, ReportStatus } from "constant";
import dayjs from "dayjs";
import { UserUUIDLink } from "views/components";

interface ReportsViewProps {
  reports: ReportRecord[];
}

export function ReportsView(props: ReportsViewProps) {
  const { reports } = props;

  const borderColor = useColorModeValue("gray.100", "gray.700");

  return (
    <Stack w="100%" overflow="auto" maxH="289px" spacing={0} >
      {reports.map((report, index) => (
        <HStack w="100%" key={index} _hover={{ bg: borderColor }} p={2} cursor="pointer">
          <UserUUIDLink uuid={report.uuid} isExternal>
            <Avatar size="sm" name={report.name} src={report.avatar?.url} />
          </UserUUIDLink>

          <Stack key={index} spacing={0}>
            <Text color={report.status === ReportStatus.Confirmed
              ? "gray.400"
              : undefined}
            >
              {report.reason}</Text>
            <Text color="gray.400">{dayjs(report.createdAt).fromNow()}</Text>
          </Stack>
        </HStack>
      ))}
    </Stack>
  );
}
