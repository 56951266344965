import { Timestamps } from "./common";
import { UserBasicInfo } from "./user";

export enum MediaType {
  Image = 0,
  Video = 1,
  Audio = 2,
}

export enum MediaStatus {
  Pending = 0, //等待审核
  Approved = 1, //审核通过
  Rejected = 2, //审核未通过
  Banned = 3, //用户被ban
  Deleted = 99, //删除
}

export enum AutoStatus {
  Undetermined = 0, // AI审核后，超过NSFW分数
  UnderReview = 1, // AI审核后, 低于NSFW分数，等待人工二次确认
  Reviewed = 2, // AI审核后, 低于NSFW分数，人工二次已确认
}

export enum DescriptionStatus {
  Pending = 0,
  Approved = 1,
  Rejected = 2,
}

export interface Media extends Timestamps {
  id: number;
  type: MediaType;
  url: string;
  status: MediaStatus;
  length: number;
  autoStatus: AutoStatus;
  riskScore: number;
  des: string;
  desStatus: DescriptionStatus;
}

export interface MediaInfo extends UserBasicInfo {
  media: Media;
}
