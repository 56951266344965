import {
  MemberSearchParams,
  MembersResp,
  MemberToCreate,
  MemberToUpdate,
  NullResp
} from "constant";
import { axiosInstance } from "./axios";
import { API_URI } from "./url";

// 创建 member
export const createMember = (params: MemberToCreate) => {
  return axiosInstance.post<NullResp>(API_URI.MEMBER_CREATE, params);
};

// 删除 member
export const deleteMember = (id: number) => {
  return axiosInstance.post<NullResp>(API_URI.MEMBER_DELETE, { id });
};

// 修改 member 信息
export const updateMember = (params: MemberToUpdate) => {
  return axiosInstance.post<NullResp>(API_URI.MEMBER_UPDATE, params);
};

// 搜索 member
export const fetchMembers = (params: MemberSearchParams) => {
  return axiosInstance.post<MembersResp>(API_URI.MEMBER_SEARCH, params);
};
