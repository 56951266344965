import { fetchMembers } from "apis";
import { Member, MemberSearchParams, MemberToCreate, Resp } from "constant";
import { atom, selector, selectorFamily } from "recoil";
import { setRecoil } from "recoil-nexus";
import { getErrorMessage } from "utils";
import { errorAtom, pageAtom, pageSizeAtom, timestampAtom } from "./common";

export const membersRespQuery = selector<Resp<Member[]> | undefined>({
  key: "members/resp",
  get: async ({ get }) => {
    // request id to refresh
    get(timestampAtom);

    const params: MemberSearchParams = {
      page: get(pageAtom),
      pageSize: get(pageSizeAtom),
    };

    try {
      const resp = await fetchMembers(params);

      if (resp.data.error) {
        setRecoil(errorAtom, getErrorMessage(resp.data.error));
      } else {
        return resp.data;
      }
    } catch (error) {
      setRecoil(errorAtom, getErrorMessage(error));
    }
  },
});

export const membersAtom = atom<Member[]>({
  key: "members",
  default: selector({
    key: "members/default",
    get: async ({ get }) => {
      const resp = get(membersRespQuery);

      return resp?.data ?? [];
    },
  }),
});

export const membersTotalQuery = selector<number>({
  key: "members/total",
  get: ({ get }) => {
    const resp = get(membersRespQuery);
    return resp?.page?.total ?? 0;
  },
});

export const memberToCreateAtom = atom<MemberToCreate | undefined>({
  key: "members/create",
  default: undefined,
});

export const currentMemberQuery = selectorFamily<Member, number>({
  key: "members/current",
  get:
    (id) =>
    ({ get }) => {
      const members = get(membersAtom);
      const member = members.find((member) => member.id === id);
      return member!;
    },
});
