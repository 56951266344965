import { fetchMedia, fetchProfiles, fetchUserDetails, fetchUsers } from "apis";
import {
  BioStatus,
  ContentToSubmit,
  MediaInfo,
  MediaInfosResp,
  MediaStatus,
  MediaType,
  Profile,
  ProfilesResp,
  ReviewStatus,
  User,
  UserDetails,
  UserDetailsResp,
  UserStatus,
  UserToSubmit,
  UsersParams,
  UsersResp,
} from "constant";
import { atom, selector } from "recoil";
import { setRecoil } from "recoil-nexus";
import { getErrorMessage } from "utils";
import { genders } from "views/layouts";
import { selectedAvatarIdsAtom } from "./avatars";
import { selectedBioIdsAtom } from "./bio";
import {
  errorAtom,
  imeiAtom,
  nameAtom,
  pageAtom,
  pageSizeAtom,
  statusAtom,
  tabAtom,
  timeRangeQuery,
  timestampAtom,
  uuidAtom,
} from "./common";
import { selectedPhotoIdsAtom } from "./photos";
import { selectedVideoIdsAtom } from "./videos";

export const usersRespQuery = selector<UsersResp | undefined>({
  key: "users/resp",
  get: async ({ get }) => {
    get(timestampAtom);

    const page = get(pageAtom);
    const pageSize = get(pageSizeAtom);
    const status = get(statusAtom);
    const timeRange = get(timeRangeQuery);

    const uuid = get(uuidAtom);
    const imei = get(imeiAtom);
    const name = get(nameAtom);

    const params: UsersParams = {
      page,
      pageSize,
    };

    if (uuid) {
      params.uuid = uuid;
    }

    if (imei) {
      params.imei = imei;
    }

    if (name) {
      params.name = name;
    }

    if (!uuid && !imei && !name) {
      if (status) {
        params.status = [parseInt(status) as UserStatus];
      }

      params.createdStart = timeRange.createdStart;
      params.createdEnd = timeRange.createdEnd;
    }

    try {
      const resp = await fetchUsers(params);
      return resp.data;
    } catch (error) {
      setRecoil(errorAtom, getErrorMessage(error));
      return undefined;
    }
  },
});

export const usersQuery = selector<User[] | undefined>({
  key: "users",
  get: ({ get }) => {
    const resp = get(usersRespQuery);

    if (resp?.error) {
      setRecoil(errorAtom, resp.error);
      return undefined;
    }

    if (resp?.data) {
      return resp.data;
    }

    return undefined;
  },
});

export const usersTotalAtom = atom({
  key: "users/total",
  default: selector({
    key: "users/total/default",
    get: ({ get }) => {
      const resp = get(usersRespQuery);
      return resp?.page?.total ?? 0;
    },
  }),
});

export const currentUUIDAtom = atom<string | undefined>({
  key: "currentUUID",
  default: undefined,
});

export const userDetailsRespQuery = selector<UserDetailsResp | undefined>({
  key: "userDetails/resp",
  get: async ({ get }) => {
    get(timestampAtom);
    const uuid = get(currentUUIDAtom);

    if (!uuid) return;

    try {
      const resp = await fetchUserDetails(uuid);
      return resp.data;
    } catch (error) {
      setRecoil(errorAtom, getErrorMessage(error));
    }
  },
});

export const userDetailsQuery = selector<UserDetails | undefined>({
  key: "userDetails",
  get: ({ get }) => {
    const resp = get(userDetailsRespQuery);

    if (resp?.error) {
      setRecoil(errorAtom, resp.error);
      return;
    }

    return resp?.data;
  },
});

// user media
export const userPhotosRespQuery = selector<MediaInfosResp | undefined>({
  key: "userPhotosResp",
  get: async ({ get }) => {
    const user = get(userDetailsQuery);

    if (!user) return;

    try {
      const resp = await fetchMedia({
        type: [MediaType.Image],
        gender: [],
        page: get(pageAtom),
        pageSize: get(pageSizeAtom),
        status: [ReviewStatus.Approved, ReviewStatus.Pending],
        uuid: user.uuid,
      });
      return resp.data;
    } catch (error) {
      setRecoil(errorAtom, getErrorMessage(error));
    }
  },
});

export const userPhotosQuery = selector<MediaInfo[]>({
  key: "userPhotos",
  get: async ({ get }) => {
    const resp = get(userPhotosRespQuery);

    if (resp?.error) {
      setRecoil(errorAtom, resp.error);
      return [];
    }

    return resp?.data ?? [];
  },
});

export const userVideosRespQuery = selector<MediaInfosResp | undefined>({
  key: "userVideosResp",
  get: async ({ get }) => {
    const user = get(userDetailsQuery);

    if (!user) return;

    try {
      const resp = await fetchMedia({
        type: [MediaType.Video],
        gender: [],
        page: get(pageAtom),
        pageSize: get(pageSizeAtom),
        status: [ReviewStatus.Approved, ReviewStatus.Pending],
        uuid: user.uuid,
      });
      return resp.data;
    } catch (error) {
      setRecoil(errorAtom, getErrorMessage(error));
    }
  },
});

export const userVideosQuery = selector<MediaInfo[]>({
  key: "userVideos",
  get: async ({ get }) => {
    const resp = get(userVideosRespQuery);

    if (resp?.error) {
      setRecoil(errorAtom, resp.error);
      return [];
    }

    return resp?.data ?? [];
  },
});

// User
export const userToSubmitQuery = selector<UserToSubmit | undefined>({
  key: "userToSubmit",
  get: ({ get }) => {
    const user = get(userDetailsQuery);

    const selectedAvatarIds = get(selectedAvatarIdsAtom);
    const selectedBioIds = get(selectedBioIdsAtom);
    const selectedPhotoIds = get(selectedPhotoIdsAtom);
    const selectedVideoIds = get(selectedVideoIdsAtom);

    if (!user) {
      return;
    }

    const userToSubmit: UserToSubmit = {
      uuid: user.uuid,
    };

    // media
    const photoInfos = get(userPhotosQuery);
    const videoInfos = get(userVideosQuery);

    const mediaInfos = photoInfos.concat(videoInfos);
    const selectedMediaIds = selectedPhotoIds.concat(selectedVideoIds);

    if (mediaInfos.length > 0) {
      let mediaToSubmit: ContentToSubmit = {
        adoptIds: [],
        refuseIds: [],
      };

      mediaInfos.forEach((mediaInfo) => {
        if (selectedMediaIds.includes(mediaInfo.media.id)) {
          // 被标记为reject
          mediaToSubmit.refuseIds.push(mediaInfo.media.id);
        } else if (mediaInfo.media.status === MediaStatus.Pending) {
          // 待审核内容，默认通过
          mediaToSubmit.adoptIds.push(mediaInfo.media.id);
        }
      });

      userToSubmit.media = mediaToSubmit;
    }

    // bio
    const { newBio } = user.extendInfo;

    let bioToSubmit: ContentToSubmit = {
      adoptIds: [],
      refuseIds: [],
    };

    if (selectedBioIds.includes(newBio.id)) {
      bioToSubmit.refuseIds.push(newBio.id);
    } else if (newBio.status === BioStatus.Pending) {
      bioToSubmit.adoptIds.push(newBio.id);
    }

    userToSubmit.bio = bioToSubmit;

    // avatar
    const { avatar } = user;

    if (avatar) {
      let avatarToSubmit: ContentToSubmit = {
        adoptIds: [],
        refuseIds: [],
      };

      if (selectedAvatarIds.includes(avatar.id)) {
        avatarToSubmit.refuseIds.push(avatar.id);
      } else if (avatar.status === MediaStatus.Pending) {
        avatarToSubmit.adoptIds.push(newBio.id);
      }

      userToSubmit.avatar = avatarToSubmit;
    }

    return userToSubmit;
  },
});

// TODO: 废弃
// Profiles
export const profilesRespQuery = selector<ProfilesResp | undefined>({
  key: "profiles/resp",
  get: async ({ get }) => {
    // request id for refresh
    get(timestampAtom);

    const page = get(pageAtom);
    const pageSize = get(pageSizeAtom);
    const gender = genders[get(tabAtom)];

    try {
      const resp = await fetchProfiles({
        page,
        pageSize,
        gender: [gender],
      });
      return resp.data;
    } catch (error) {
      setRecoil(errorAtom, getErrorMessage(error));
      return undefined;
    }
  },
});

export const profilesAtom = atom<Profile[] | undefined>({
  key: "profiles",
  default: selector({
    key: "profiles/default",
    get: async ({ get }) => {
      const resp = get(profilesRespQuery);

      if (resp?.error) {
        setRecoil(errorAtom, resp.error);
        return undefined;
      }

      if (resp?.data) {
        return resp.data;
      }

      return undefined;
    },
  }),
});

export const profilesTotalQuery = selector({
  key: "profiles/total",
  get: ({ get }) => {
    const resp = get(profilesRespQuery);
    return resp?.page?.total ?? 0;
  },
});

export const profilesToSubmitQuery = selector<ContentToSubmit | undefined>({
  key: "profilesToSubmit",
  get: ({ get }) => {
    const profiles = get(profilesAtom);
    const selects = get(selectedPhotoIdsAtom);

    if (!profiles) {
      return;
    }

    let mediaToSubmit: ContentToSubmit = {
      adoptIds: [],
      refuseIds: [],
    };

    profiles.forEach((profile) => {
      if (!profile.medias) {
        return;
      }

      profile.medias.forEach((media) => {
        if (selects.includes(media.id)) {
          // 标记为reject
          mediaToSubmit.refuseIds.push(media.id);
        } else if (media.status === MediaStatus.Pending) {
          // 新内容，待审核，默认通过
          mediaToSubmit.adoptIds.push(media.id);
        }
      });
    });

    return mediaToSubmit;
  },
});
