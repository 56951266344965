import { Button } from "@chakra-ui/react";
import {
  submitAvatars,
  submitBios,
  submitDescriptions,
  submitMedia,
} from "apis";
import { AxiosResponse } from "axios";
import { ContentToSubmit, NullResp } from "constant";
import dayjs from "dayjs";
import { Suspense, useState } from "react";
import {
  RecoilState,
  RecoilValueReadOnly,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import {
  avatarsToSubmitQuery,
  bioToSubmitQuery,
  errorAtom,
  photosToSubmitQuery,
  profilesToSubmitQuery,
  selectedAvatarIdsAtom,
  selectedBioIdsAtom,
  selectedPhotoIdsAtom,
  selectedVideoIdsAtom,
  timestampAtom,
  videosToSubmitQuery,
} from "state";
import {
  descriptionsToSubmitQuery,
  selectedDescriptionIdsAtom,
} from "state/description";
import { getErrorMessage } from "utils";

interface Configure {
  dataSourceState: RecoilValueReadOnly<ContentToSubmit | undefined>;
  selectedState: RecoilState<number[]>;
  api: (params: ContentToSubmit) => Promise<AxiosResponse<NullResp, any>>;
}

interface ContentToSubmitButtonProps {
  config: Configure;
}

function ContentToSubmitButton(props: ContentToSubmitButtonProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const setError = useSetRecoilState(errorAtom);
  const setTimestamp = useSetRecoilState(timestampAtom);

  const contentToSubmit = useRecoilValue(props.config.dataSourceState);
  const restSelectedIds = useResetRecoilState(props.config.selectedState);

  if (!contentToSubmit) return <></>;

  const _handleClick = async () => {
    console.log(contentToSubmit);

    setIsSubmitting(true);

    try {
      const resp = await props.config.api(contentToSubmit);

      setIsSubmitting(false);

      if (resp.data.error) {
        setError(resp.data.error);
      } else {
        restSelectedIds();
        setTimestamp(dayjs().valueOf());
      }
    } catch (error) {
      setIsSubmitting(false);
      setError(getErrorMessage(error));
    }
  };

  return (
    <Button
      isLoading={isSubmitting}
      onClick={_handleClick}>
      Submit
    </Button>
  );
}

function ContentToSubmitButtonWrapper(props: ContentToSubmitButtonProps) {
  return (
    <Suspense fallback={<Button isLoading={true}>Submit</Button>}>
      <ContentToSubmitButton config={props.config} />
    </Suspense>
  );
}
export function SubmitAvatarsButton() {
  const config: Configure = {
    dataSourceState: avatarsToSubmitQuery,
    selectedState: selectedAvatarIdsAtom,
    api: submitAvatars,
  };
  return <ContentToSubmitButtonWrapper config={config} />;
}

export function SubmitPhotosButton() {
  const config: Configure = {
    dataSourceState: photosToSubmitQuery,
    selectedState: selectedPhotoIdsAtom,
    api: submitMedia,
  };
  return <ContentToSubmitButtonWrapper config={config} />;
}

export function SubmitVideosButton() {
  const config: Configure = {
    dataSourceState: videosToSubmitQuery,
    selectedState: selectedVideoIdsAtom,
    api: submitMedia,
  };
  return <ContentToSubmitButtonWrapper config={config} />;
}

export function SubmitBiosButton() {
  const config: Configure = {
    dataSourceState: bioToSubmitQuery,
    selectedState: selectedBioIdsAtom,
    api: submitBios,
  };
  return <ContentToSubmitButtonWrapper config={config} />;
}

export function SubmitDescriptionsButton() {
  const config: Configure = {
    dataSourceState: descriptionsToSubmitQuery,
    selectedState: selectedDescriptionIdsAtom,
    api: submitDescriptions,
  };
  return <ContentToSubmitButtonWrapper config={config} />;
}

// TODO：遗弃
export function ProfilesToSubmitButton() {
  const config: Configure = {
    dataSourceState: profilesToSubmitQuery,
    selectedState: selectedPhotoIdsAtom,
    api: submitMedia,
  };
  return <ContentToSubmitButtonWrapper config={config} />;
}
