import { Avatar, Box, HStack, Menu, MenuButton, MenuDivider, MenuItem, MenuList, Spacer, Text, useColorModeValue } from "@chakra-ui/react"
import { useRecoilValue } from "recoil"
import { signedInUserAtom } from "state"
import { auth } from "../../../firebase"
import { RefreshButton } from "../RefreshButton"
import { Breadcrumbs } from "./Breadcrumbs"
import { ColorModeSwitcher } from "./ColorModeSwitcher"
import { SearchBar } from "./SearchBar"

export const NavigationBar = () => {

  const navBg = useColorModeValue("white", "gray.800")

  return (
    <HStack position="sticky" px={2} h="52px" bg={navBg} zIndex={80}>
      <Breadcrumbs />
      <Spacer />
      <RefreshButton />
      <SearchBar />
      <ColorModeSwitcher />
      <CurrentUserInfo />
    </HStack>
  )
}


function CurrentUserInfo() {
  // DEBUG 模式，跳过auth要求
  if (process.env.REACT_APP_DEBUG) {
    return (
      <Box>
        Debug Mode
      </Box>);
  }

  return (
    <Box>
      {
        process.env.REACT_APP_DEBUG
          ? <Text>Debug Mode</Text>
          : <SignedUserInfo />
      }
    </Box>
  )
}

function SignedUserInfo() {
  const signedInUser = useRecoilValue(signedInUserAtom)

  const handleSignOut = () => {
    auth.signOut()
  }

  return (
    <>
      {signedInUser &&

        <Menu>
          <MenuButton>
            <Avatar size="sm" src={signedInUser.photoURL ?? undefined} name={signedInUser.displayName ?? undefined} />
          </MenuButton>
          <MenuList>
            <MenuItem closeOnSelect={false}>
              {signedInUser.email}
            </MenuItem>
            <MenuDivider />
            <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
          </MenuList>
        </Menu>

      }
    </>
  )
}