import { Box, Select } from "@chakra-ui/react";
import { ReviewStatus } from "constant";
import { useRecoilState, useResetRecoilState } from "recoil";
import { nextStatusAtom } from "state";
import { autoStatusSelectAtom } from "./AutoStatusSelect";

export const statuses = [
  ReviewStatus.Pending,
  ReviewStatus.Approved,
  ReviewStatus.Rejected,
];

export function StatusSelect() {
  const [status, setStatus] = useRecoilState(nextStatusAtom);
  const resetAutoStatusSelect = useResetRecoilState(autoStatusSelectAtom);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setStatus(+e.target.value);
    resetAutoStatusSelect();
  };
  return (
    <Box>
      <Select
        defaultValue={status}
        onChange={handleChange}>
        {statuses.map((status) => (
          <option
            key={status}
            value={status}>
            {ReviewStatus[status]}
          </option>
        ))}
      </Select>
    </Box>
  );
}
