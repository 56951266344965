import { PageParams, Resp, Timestamp } from "./common";
import { AutoStatus, Media, MediaInfo, MediaType } from "./media";
import {
  BioStatus,
  Gender,
  Identity,
  ReportRecord,
  UserBasicInfo,
} from "./user";

export enum ReviewStatus {
  Pending = 0, //等待审核
  Approved = 1, //审核通过
  Rejected = 2, //审核未通过
}

export interface BaseReviewParams extends PageParams {
  gender: Gender[];
  status?: ReviewStatus[];
}

export interface AvatarsParams extends BaseReviewParams {
  autoStatus?: AutoStatus[];
}

export interface MediaParams extends BaseReviewParams {
  type: MediaType[];
  uuid?: string;
  autoStatus?: AutoStatus[];
}
export type PhotosParams = MediaParams;
export type VideosParams = MediaParams;

export interface BiosParams extends BaseReviewParams {
  autoStatus?: AutoStatus[];
}

export interface DescriptionsParams extends BaseReviewParams {
  desStatus: ReviewStatus[];
  autoStatus?: AutoStatus[];
}

export type ReportsParams = BaseReviewParams;

export type AvatarsResp = Resp<UserBasicInfo[]>;
export type MediaInfosResp = Resp<MediaInfo[]>;
export type PhotosResp = Resp<MediaInfo[]>;
export type VideosResp = Resp<MediaInfo[]>;
export type BiosResp = Resp<Bio[]>;
export type DescriptionsResp = Resp<Description[]>;
export type ReportsResp = Resp<Report[]>;

// TODO: 废弃
export type ProfilesParams = BaseReviewParams;
export type ProfilesResp = Resp<Profile[]>;

export interface Profile extends UserBasicInfo {
  gender?: Gender;
  birthday?: Timestamp;
  likeIdentity?: Identity; // fan or creator, 自己的标识与identify相反
  medias?: Media[];
}
export interface TextContent extends UserBasicInfo {
  id: number;
  content: string;
  status: BioStatus;
  riskStatus: number; // TODO: status enum
  createdAt: Timestamp;
  updatedAt: Timestamp;
}

export type Bio = TextContent;
export type Description = TextContent;

export interface Report extends UserBasicInfo {
  reports: ReportRecord[]; // 被举报记录
}

// content to submit
export interface ContentToSubmit {
  adoptIds: number[];
  refuseIds: number[];
}
