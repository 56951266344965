import { Button } from "@chakra-ui/react";
import { submitUser } from "apis";
import dayjs from "dayjs";
import { useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { errorAtom, timestampAtom, userToSubmitQuery } from "state";
import { getErrorMessage } from "utils";

export function SubmitUserButton() {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const userToSubmit = useRecoilValue(userToSubmitQuery);

  const refresh = useSetRecoilState(timestampAtom);
  const setError = useSetRecoilState(errorAtom);

  const handleSubmit = async () => {
    console.log(userToSubmit);

    if (!userToSubmit) {
      return;
    }

    setIsSubmitting(true);

    try {
      const resp = await submitUser(userToSubmit);

      setIsSubmitting(false);

      if (resp.data.error) {
        setError(resp.data.error);
      } else {
        // 成功提交，刷新数据
        refresh(dayjs().valueOf());
      }
    } catch (error) {
      setIsSubmitting(false);
      setError(getErrorMessage(error));
    }
  };

  return (
    <Button
      isLoading={isSubmitting}
      onClick={handleSubmit}>
      Submit
    </Button>
  );
}
