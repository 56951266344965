import {
  Editable,
  EditableInput,
  EditablePreview, Text
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { pageAtom, totalPagesQuery } from "state";
import { PaginationProps } from "./Pagination";

export function TotalPageView(props: PaginationProps) {

  const [page, setPage] = useRecoilState(pageAtom);
  const totalPages = useRecoilValue(totalPagesQuery(props.state));

  const [p, setP] = useState(page.toString());

  // 修改page
  const handleSubmit = () => {
    let _p = parseInt(p);

    if (!_p || _p < 1) {
      // 输入的页码不合法：非数字或小于1
      _p = 1;
    } else if (_p > totalPages) {
      // 输入的页码超出最大页面数
      _p = totalPages;
    }

    // 修改page
    setPage(_p);
  };

  // page state修改时，刷新page耶main展示内容
  useEffect(() => {
    setP(page.toString())
  }, [page])

  return (
    <>
      <Editable
        defaultValue={page.toString()}
        value={p}
        onChange={setP}
        onSubmit={handleSubmit}
        submitOnBlur={true}
        fontWeight="semibold">
        <EditablePreview />
        <EditableInput w="40px" />
      </Editable>
      <Text>/ {totalPages}</Text>
    </>
  );
}
