import {
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { banUser, deleteUser, toggleDeviceStatus } from "apis";
import { BanAction, DeviceStatus, UserStatus } from "constant";
import dayjs from "dayjs";
import { FiMoreVertical } from "react-icons/fi";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { errorAtom, timestampAtom, userDetailsQuery } from "state";
import { getErrorMessage } from "utils";

export function MoreButton() {
  const user = useRecoilValue(userDetailsQuery);
  const setError = useSetRecoilState(errorAtom);
  const setTimestamp = useSetRecoilState(timestampAtom);

  const handleToggleDeviceStatus = async () => {
    if (!user) return;

    try {
      const resp = await toggleDeviceStatus(user.device.id);
      if (resp.data.error) {
        setError(resp.data.error);
      } else {
        // success
        setTimestamp(dayjs().valueOf());
      }
    } catch (error) {
      setError(getErrorMessage(error));
    }
  };

  const handleBanUser = async () => {
    if (!user) return;

    try {
      const resp = await banUser({
        uuid: user.uuid,
        banned: BanAction.Yes,
      });

      if (resp.data.error) {
        setError(resp.data.error);
      } else {
        // success
        setTimestamp(dayjs().valueOf());
      }
    } catch (error) {
      setError(getErrorMessage(error));
    }
  };

  const handleDeleteUser = async () => {
    if (!user) return;

    try {
      const resp = await deleteUser(user.uuid);

      if (resp.data.error) {
        setError(resp.data.error);
      } else {
        // success
        setTimestamp(dayjs().valueOf());
      }
    } catch (error) {
      setError(getErrorMessage(error));
    }
  };

  return (
    <Box>
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<FiMoreVertical />}
          variant="outline"
        />

        <MenuList>
          {user?.device && (
            <MenuItem onClick={handleToggleDeviceStatus}>
              {user.device.status === DeviceStatus.Enabled ? "Ban" : "Un-Ban"}{" "}
              Device
            </MenuItem>
          )}
          {user?.status &&
            [UserStatus.Approved, UserStatus.New, UserStatus.Pending].includes(
              user.status
            ) && <MenuItem onClick={handleBanUser}>Ban Profile</MenuItem>}

          <MenuDivider />
          {user?.status && user.status !== UserStatus.Deleted && (
            <MenuItem
              color="red"
              onClick={handleDeleteUser}>
              Delete Account
            </MenuItem>
          )}
        </MenuList>
      </Menu>
    </Box>
  );
}
