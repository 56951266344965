
import dayjs from "dayjs";
import React from "react";
import { useRecoilState, useResetRecoilState } from "recoil";
import { monthAtom, pageAtom } from "state";
import { FilterOption, FilterSelector } from "./FilterSelector";


export function MonthFilter() {

  const [month, setMonth] = useRecoilState(monthAtom)

  const resetPage = useResetRecoilState(pageAtom)
  const resetMonth = useResetRecoilState(monthAtom)

  // 生成select options数组
  // 项目起始时间：January 2023
  const options = generateOptions("January 2023", "MMMM YYYY", "YYYYMM");

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event.target.value === "") {
      resetMonth()
    } else {
      setMonth(event.target.value)
      resetPage()
    }

  };

  return (
    <FilterSelector
      options={options}
      defaultValue={month}
      onChange={handleChange}
    />
  );
}


// generateDateOptions 更具指定的起始年月字符串，生成形如{title: "June 2021", value: 202106}的连续数组
// params: {
//    from: string
// }
// return: FilterOption[]
const generateOptions = (startMonthAndYear: string, titleFormat: string, valueFormat: string): FilterOption[] => {
  const start = dayjs(startMonthAndYear, titleFormat);
  const end = dayjs();

  // 临时容器
  let dateOptions: FilterOption[] = [];

  // dayjs的对象是不可修改的，拷贝一份，才可以修改
  let endCache = end.clone();

  // 循环生成DateOption
  while (start.isBefore(endCache)) {
    // 结果放入dateOptions
    dateOptions.push({
      title: endCache.format(titleFormat),
      value: parseInt(endCache.format(valueFormat)),
    });

    // 减一月，下一轮
    endCache = endCache.subtract(1, "month");
  }

  return dateOptions;
};