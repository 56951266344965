import { Highlight, Table, TableCaption, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { highlightQuery, TextContent } from "constant";
import dayjs from "dayjs";
import _ from "lodash";
import { Suspense } from "react";
import { RecoilState, RecoilValueReadOnly, useRecoilState, useRecoilValue } from "recoil";
import { CustomTr, TableSpinner, UserLink } from "views/components";

export type BaseTextTableProps = {
  dataSourceState: RecoilValueReadOnly<TextContent[] | undefined>;
  selectedIdsState: RecoilState<number[]>;
};

export function TextTable(props: BaseTextTableProps) {
  return (
    <Table>
      <Thead>
        <Tr>
          <Th>User</Th>
          <Th>Content</Th>
          <Th whiteSpace="nowrap">From Now</Th>
        </Tr>
      </Thead>
      <Suspense fallback={<TableSpinner />}>
        <TextTableBody {...props} />
      </Suspense>
    </Table>
  );
}
function TextTableBody(props: BaseTextTableProps) {

  const contents = useRecoilValue(props.dataSourceState);

  return (
    <>
      {!contents
        ? <TableCaption>No Results</TableCaption>
        : <Tbody>
          {contents.map((content, i) => (
            <TextTableCell key={i} data={content} selectedIdsState={props.selectedIdsState} />
          ))}</Tbody>}
    </>
  );

}
interface TextTableCellProps {
  data: TextContent;
  selectedIdsState: RecoilState<number[]>;
}
function TextTableCell(props: TextTableCellProps) {
  const { data } = props;

  const [selectedIds, selectId] = useRecoilState(props.selectedIdsState);
  const isSelected = selectedIds.includes(data.id);

  const handleSelect = () => {
    selectId(curr => {
      const ids = _.cloneDeep(curr);

      isSelected
        ? _.remove(ids, id => id === data.id)
        : ids.push(data.id);
      return ids;
    });
  };

  return (
    <CustomTr onClick={handleSelect}>
      <Td w={1}>
        <UserLink
          isExternal
          uuid={data.uuid}
          name={data.name}
          avatar={data.avatar} />
      </Td>
      <Td bg={isSelected ? "red.200" : undefined}>
        <Text>
          {data.content &&
            <Highlight
              query={highlightQuery}
              styles={{ px: '2', py: '1', rounded: 'full', bg: 'red.100' }}
            >
              {data.content}
            </Highlight>}
        </Text>
      </Td>
      <Td w={1} whiteSpace="nowrap">{dayjs(data.createdAt).fromNow()}</Td>
    </CustomTr>
  );
}
