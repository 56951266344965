import { Box, Flex } from "@chakra-ui/react";
import { Outlet } from "react-router";
import { useRecoilValue } from "recoil";
import { sidebarCollapseAtom } from "state";
import { NavigationBar, Sidebar } from "views/components";

// MainLayout 主布局
export const MainLayout = () => {
  const isOpen = useRecoilValue(sidebarCollapseAtom)

  return (
    <Flex height="100vh">
      <Box w={isOpen ? "200px" : undefined} overflowY="auto" zIndex={3}>
        <Sidebar />
      </Box>

      <Box flex="1" overflowY="auto">
        <NavigationBar />
        <Box p={2}>
          <Outlet />
        </Box>
      </Box>
    </Flex>
  );
};
