import {
  PageParams,
  Resp,
  TimeRangeParams,
  Timestamp,
  Timestamps,
} from "./common";
import { Device } from "./device";
import { Media } from "./media";
import { ContentToSubmit } from "./review";

export enum Gender {
  Unknown = 0,
  Man = 1,
  Woman = 2,
  NonBinary = 4,
}

export enum Identity {
  Fan = 1,
  Creator = 2,
}

export interface UserBasicInfo {
  uuid: string;
  name?: string;
  avatar?: Media;
}

export interface UsersParams extends TimeRangeParams, PageParams {
  name?: string;
  uuid?: string;
  imei?: string; // device id
  status?: UserStatus[];
  identify?: Identity[];
}

export type UsersResp = Resp<User[]>;
export type UserDetailsResp = Resp<UserDetails>;

export interface User extends UserBasicInfo, Timestamps {
  gender?: Gender;
  likeIdentity?: Identity;
  birthday: Timestamp;
  extendInfo: ExtendInfo;
  link: SocialLink;
  status: UserStatus;
}

export interface UserDetails extends User {
  discoverFilter: Filter;
  device: Device;
  lastSubscription: UserSubscription;
  reports?: ReportRecord[];
  config: UserConfig;
}

export interface UserConfig {
  language: string;
}

export interface UserToSubmit {
  uuid: string;
  bio?: ContentToSubmit;
  media?: ContentToSubmit;
  avatar?: ContentToSubmit;
  mediaDes?: ContentToSubmit;
}

export interface UserToBan {
  uuid: string;
  banned: BanAction;
}

export interface UserToEdit {
  uuid: string;
  gender?: Gender;
  likeIdentity?: Identity;
}

export enum BanAction {
  No,
  Yes,
}

export enum UserStatus {
  New = 0, // new
  Pending = 1, // 待审核
  Rejected = 2, // 未通过
  Approved = 3, // 通过
  Banned = 98, // 用户被搬
  Deleted = 99, // 删除
}

export interface SocialLink {
  instagram?: string;
  onlyFans?: string;
  snapchat?: string;
  tiktok?: string;
}

export interface BioInfo extends Timestamps {
  id: number;
  content: string;
  status: BioStatus;
}

export interface UserBio {
  newBio: BioInfo;
  oldBio?: BioInfo;
}

export enum BioStatus {
  Pending = 0,
  Approved = 1,
  Rejected = 2,
  UnCompleted = 99,
}

export interface ExtendInfo extends UserBio {
  city?: string;
  country?: string;
  ip?: string;
  lat?: number;
  lng?: number;
}

export interface UserSubscription {
  vip: Membership;
  productId?: string;
  productName?: string;
  startDate?: Timestamp;
  expiresDate?: Timestamp;
}

export enum Membership {
  Normal,
  VIP,
}

export enum ReportStatus {
  // 举报状态0：等待 1：确认
  Pending = 0,
  Confirmed = 1,
}

export interface ReportRecord extends UserBasicInfo, Timestamps {
  reason: string;
  status: ReportStatus;
}

export interface Filter {
  endAge: number;
  likeGenders: Gender[];
  maxDistance: number; // distance unit: mi
  startAge: number;
}
