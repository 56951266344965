import { Button, FormControl, FormLabel, HStack, Input, MenuItem, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Stack, useDisclosure } from "@chakra-ui/react";
import { updateMember } from "apis";
import { MemberStatus, MemberToUpdate } from "constant";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";
import { FiEdit2 } from "react-icons/fi";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { currentMemberQuery, errorAtom, timestampAtom } from "state";
import { getErrorMessage } from "utils";

const statuses = [MemberStatus.Activated, MemberStatus.Disabled, MemberStatus.Invited];

interface EditMemberMenuItemProps {
  id: number;
}

export const EditMemberMenuItem = (props: EditMemberMenuItemProps) => {

  const { isOpen, onOpen, onClose } = useDisclosure();
  const currentMember = useRecoilValue(currentMemberQuery(props.id))
  const setError = useSetRecoilState(errorAtom)
  const setTimestamp = useSetRecoilState(timestampAtom)

  const defaultValues: MemberToUpdate = {
    id: currentMember.id,
    status: currentMember.status,
    name: currentMember.name,
    email: currentMember.email
  }

  const { register, handleSubmit, reset, formState } = useForm<MemberToUpdate>({ defaultValues })

  const submit = async (data: MemberToUpdate) => {

    try {
      const resp = await updateMember(data)
      if (resp.data.error) {
        setError(resp.data.error)
      } else {
        setTimestamp(dayjs().valueOf())
        onClose()
      }
    } catch (error) {
      setError(getErrorMessage(error))
    }
  }

  const handleClose = () => {
    onClose()
    reset()
  }

  return (
    <>
      <MenuItem icon={<FiEdit2 />} onClick={onOpen}>
        Edit
      </MenuItem>

      <Modal
        isCentered
        // initialFocusRef={initialRef}
        onClose={onClose}
        isOpen={isOpen}
        motionPreset="slideInBottom"
        scrollBehavior="inside">
        <ModalOverlay />

        <ModalContent>
          <form onSubmit={handleSubmit(submit)}>
            <ModalHeader>Update Member</ModalHeader>

            <ModalBody>
              <Stack spacing={4}>
                <FormControl isRequired>
                  <FormLabel>Name</FormLabel>
                  <Input placeholder="Name" {...register("name")} />
                </FormControl>

                <FormControl isRequired>
                  <FormLabel>Email</FormLabel>
                  <Input type="email" placeholder="Email" {...register("email")} />
                </FormControl>

                <FormControl isRequired>
                  <FormLabel>Status</FormLabel>
                  <Select {...register("status")}>
                    {statuses.map((status, index) => <option key={index} value={status}>{MemberStatus[status]}</option>)}
                  </Select>
                </FormControl>

              </Stack>
            </ModalBody>

            <ModalFooter>
              <HStack>
                <Button variant='ghost' onClick={handleClose}>Close</Button>
                <Button isLoading={formState.isSubmitting} type="submit">Update</Button>
              </HStack>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};
