import { fetchDevices } from "apis";
import { Device, DevicesParams, DevicesResp, DeviceStatus } from "constant";
import { atom, selector } from "recoil";
import { setRecoil } from "recoil-nexus";
import { getErrorMessage } from "utils";
import {
  errorAtom,
  imeiAtom,
  pageAtom,
  pageSizeAtom,
  statusAtom,
  timeRangeQuery,
  timestampAtom
} from "./common";

export const devicesRespQuery = selector<DevicesResp | undefined>({
  key: "devices/resp",
  get: async ({ get }) => {
    get(timestampAtom);

    const page = get(pageAtom);
    const pageSize = get(pageSizeAtom);
    const timeRange = get(timeRangeQuery);
    const status = get(statusAtom);

    const imei = get(imeiAtom);

    const params: DevicesParams = {
      page,
      pageSize,
    };

    if (imei) {
      params.imei = imei;
    } else {
      if (status) {
        params.status = [parseInt(status) as DeviceStatus];
      }

      params.createdStart = timeRange.createdStart;
      params.createdEnd = timeRange.createdEnd;
    }

    try {
      const resp = await fetchDevices(params);
      return resp.data;
    } catch (error) {
      setRecoil(errorAtom, getErrorMessage(error));
      return undefined;
    }
  },
});

export const devicesQuery = selector<Device[] | undefined>({
  key: "devices",
  get: ({ get }) => {
    const resp = get(devicesRespQuery);

    if (resp?.error) {
      setRecoil(errorAtom, resp.error);
      return undefined;
    }

    if (resp?.data) {
      return resp.data;
    }

    return undefined;
  },
});

export const devicesTotalAtom = atom({
  key: "devices/total",
  default: selector({
    key: "devices/total/default",
    get: ({ get }) => {
      const resp = get(devicesRespQuery);
      return resp?.page?.total ?? 0;
    },
  }),
});
