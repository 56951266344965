import {
  HStack
} from "@chakra-ui/react";

import { Suspense } from "react";
import { RecoilValueReadOnly, useRecoilValue } from "recoil";
import { PaginationNextButton } from "./PaginationNextButton";
import { PaginationPrevButton } from "./PaginationPrevButton";
import { PaginationTotalView } from "./PaginationTotalView";
import { TotalPageView } from "./TotalPageView";

export interface PaginationProps {
  state: RecoilValueReadOnly<number>
}

export function Pagination(props: PaginationProps) {
  return (
    <Suspense fallback={<></>}>
      <PaginationView state={props.state} />
    </Suspense>
  );
}

export function PaginationView(props: PaginationProps) {
  const total = useRecoilValue(props.state)

  return (
    <>
      {total > 0 &&
        <HStack>
          <PaginationTotalView total={total} />

          <PaginationPrevButton />
          <TotalPageView state={props.state} />
          <PaginationNextButton state={props.state} />
        </HStack>
      }
    </>
  )
}