import { MediaStatus } from "constant";

export const getMediaTagColors = (status: MediaStatus) => {
  switch (status) {
    case MediaStatus.Pending:
      return ["white", "red"];
    case MediaStatus.Approved:
      return ["white", "green"];
    case MediaStatus.Rejected:
      return ["white", "gray"];
    default:
      return ["white", "gray"];
  }
};
