
import { HStack, StackProps, useColorModeValue } from "@chakra-ui/react";

export function HeaderStack(props: StackProps) {
  const headerBg = useColorModeValue("white", "gray.800");

  return (
    <HStack position="sticky" top="0" py={2} zIndex={4} w="100%" bg={headerBg}>
      {props.children}
    </HStack>
  );
}
