import { DeviceStatus } from "constant";
import { FilterOption } from "./FilterSelector";
import { StatusFilter } from "./StatusFilter";

const statuses = [
  DeviceStatus.Enabled,
  DeviceStatus.Disabled,
]

export function DeviceStatusFilter() {
  const options = statuses.map((status) => {
    const option: FilterOption = {
      title: DeviceStatus[status],
      value: status,
    };
    return option;
  });

  return <StatusFilter options={options} />;
}
