import {
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Select,
  useDisclosure
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { BsSearch } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useResetRecoilState, useSetRecoilState } from "recoil";
import { RouteURI } from "routers";
import { imeiAtom, nameAtom, pageAtom, uuidAtom } from "state";

interface SearchBarForm {
  type: SearchType;
  keyword: string;
}

enum SearchType {
  UUID = "uuid",
  IMEI = "IMEI",
  Name = "Name",
}

const searchTypes = [SearchType.UUID, SearchType.IMEI, SearchType.Name];

export function SearchBar() {
  const navigate = useNavigate();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { register, handleSubmit } = useForm<SearchBarForm>({
    defaultValues: {
      type: SearchType.UUID,
      keyword: ""
    }
  });

  const setIMEI = useSetRecoilState(imeiAtom)
  const setUUID = useSetRecoilState(uuidAtom)
  const setName = useSetRecoilState(nameAtom)

  const resetPage = useResetRecoilState(pageAtom)
  const resetUUID = useResetRecoilState(uuidAtom)
  const resetIMEI = useResetRecoilState(imeiAtom)
  const resetName = useResetRecoilState(nameAtom)

  const handleSearch = handleSubmit(async (data) => {
    resetPage()
    resetIMEI()
    resetUUID()
    resetName()

    const { type, keyword } = data;

    switch (type) {
      case SearchType.UUID:
        keyword && setUUID(keyword)
        navigate(RouteURI.Users);
        break;
      case SearchType.IMEI:
        keyword && setIMEI(keyword)
        navigate(RouteURI.Devices);
        break;
      case SearchType.Name:
        keyword && setName(keyword)
        navigate(RouteURI.Users);
        break
    }

    // 关闭modal
    onClose();
  });

  return (
    <>
      <IconButton
        aria-label="Search"
        variant="ghost"
        icon={<BsSearch />}
        onClick={onOpen}
      />

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />

        <ModalContent>
          <ModalBody>
            <form onSubmit={handleSearch}>
              <HStack>
                <Select size="lg" w="144px" variant="flushed" {...register("type")}>
                  {searchTypes.map((searchType, index) => (
                    <option key={index} value={searchType} >
                      {searchType.toString().toUpperCase()}
                    </option>
                  ))}
                </Select>

                <InputGroup size="lg" flex={1} variant="flushed">
                  <InputLeftElement
                    pointerEvents="none"
                    children={<BsSearch color="gray.300" />}
                  />
                  <Input type="text" placeholder="Search" {...register("keyword")} />
                </InputGroup>
              </HStack>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
