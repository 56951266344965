import { fetchSubscriptions, fetchTransfers } from "apis";
import {
  Subscription,
  SubscriptionsParams,
  SubscriptionsResp,
  Transfer,
  TransfersParams,
  TransfersResp
} from "constant";
import { atom, selector } from "recoil";
import { setRecoil } from "recoil-nexus";
import { getErrorMessage } from "utils";
import {
  errorAtom,
  pageAtom,
  pageSizeAtom,
  timeRangeQuery,
  timestampAtom
} from "./common";

export const subscriptionsRespQuery = selector<SubscriptionsResp | undefined>({
  key: "subscriptions/resp",
  get: async ({ get }) => {
    get(timestampAtom);

    const page = get(pageAtom);
    const pageSize = get(pageSizeAtom);
    const timeRange = get(timeRangeQuery);

    const params: SubscriptionsParams = {
      page,
      pageSize,
      ...timeRange,
    };

    // TODO: search params

    try {
      const resp = await fetchSubscriptions(params);
      return resp.data;
    } catch (error) {
      setRecoil(errorAtom, getErrorMessage(error));
      return undefined;
    }
  },
});

export const subscriptionsQuery = selector<Subscription[] | undefined>({
  key: "subscriptions",
  get: ({ get }) => {
    const resp = get(subscriptionsRespQuery);

    if (resp?.error) {
      setRecoil(errorAtom, resp.error);
      return undefined;
    }

    if (resp?.data) {
      return resp.data;
    }

    return undefined;
  },
});

export const subscriptionsTotalAtom = atom({
  key: "subscriptions/total",
  default: selector({
    key: "subscriptions/total/default",
    get: ({ get }) => {
      const resp = get(subscriptionsRespQuery);
      return resp?.page?.total ?? 0;
    },
  }),
});

export const transfersRespQuery = selector<TransfersResp | undefined>({
  key: "transfers/resp",
  get: async ({ get }) => {
    get(timestampAtom);

    const page = get(pageAtom);
    const pageSize = get(pageSizeAtom);
    const timeRange = get(timeRangeQuery);

    const params: TransfersParams = {
      page,
      pageSize,
      ...timeRange,
    };

    // TODO: search params

    try {
      const resp = await fetchTransfers(params);
      return resp.data;
    } catch (error) {
      setRecoil(errorAtom, getErrorMessage(error));
      return undefined;
    }
  },
});

export const transfersQuery = selector<Transfer[] | undefined>({
  key: "transfers",
  get: ({ get }) => {
    const resp = get(transfersRespQuery);

    if (resp?.error) {
      setRecoil(errorAtom, resp.error);
      return undefined;
    }

    if (resp?.data) {
      return resp.data;
    }

    return undefined;
  },
});

export const transfersTotalAtom = atom({
  key: "transfers/total",
  default: selector({
    key: "transfers/total/default",
    get: ({ get }) => {
      const resp = get(transfersRespQuery);
      return resp?.page?.total ?? 0;
    },
  }),
});
