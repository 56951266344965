import { Icon, Stack, useColorModeValue } from "@chakra-ui/react";
import { BsTextIndentLeft, BsTextIndentRight } from "react-icons/bs";
import { useRecoilState } from "recoil";
import { sidebarCollapseAtom } from "state";

export function CollapseEx() {
  const [isOpen, toggle] = useRecoilState(sidebarCollapseAtom);

  const bg = useColorModeValue("gray.100", "gray.600");

  return (
    <Stack cursor="pointer" onClick={() => toggle(curr => !curr)} bg={bg} py={1} alignItems="center">
      <Icon
        boxSize="24px"
        as={isOpen ? BsTextIndentRight : BsTextIndentLeft}
      />
    </Stack>
  );
}
