
import { Spacer, Stack, useColorModeValue } from "@chakra-ui/react";
import { BiTransfer } from "react-icons/bi";
import { BsFileEarmarkRichtext, BsFileEarmarkText } from "react-icons/bs";
import { FiSmartphone, FiUsers } from "react-icons/fi";
import { HiOutlinePhotograph, HiOutlineVideoCamera } from 'react-icons/hi';
import { RiAdminLine, RiDashboardLine, RiVipCrownLine } from "react-icons/ri";
import { RxAvatar } from 'react-icons/rx';
import { VscReport } from "react-icons/vsc";
import { RouteURI } from "routers";
import { CollapseEx } from "./CollapseEx";
import { Logo } from "./Logo";
import { SidebarCapital } from "./SidebarCapital";
import { SidebarNavLink } from "./SidebarNavLink";

export const Sidebar = () => {
  const bg = useColorModeValue("gray.50", "gray.700");

  return (
    <Stack as="aside" h="100%" bg={bg} spacing={0}>
      <Logo />

      <SidebarNavLink to={RouteURI.Dashboard} icon={RiDashboardLine} />

      <>
        <SidebarCapital title="Review" />
        <SidebarNavLink to={RouteURI.Avatars} icon={RxAvatar} />
        <SidebarNavLink to={RouteURI.Photos} icon={HiOutlinePhotograph} />
        <SidebarNavLink to={RouteURI.Videos} icon={HiOutlineVideoCamera} />
        <SidebarNavLink to={RouteURI.Bios} icon={BsFileEarmarkText} />
        <SidebarNavLink to={RouteURI.Descriptions} icon={BsFileEarmarkRichtext} />
        <SidebarNavLink to={RouteURI.Reports} icon={VscReport} />
      </>

      <>
        <SidebarCapital title="Data" />
        <SidebarNavLink to={RouteURI.Users} icon={FiUsers} />
        <SidebarNavLink to={RouteURI.Devices} icon={FiSmartphone} />
      </>

      <>
        <SidebarCapital title="Purchase" />
        <SidebarNavLink to={RouteURI.Subscriptions} icon={RiVipCrownLine} />
        <SidebarNavLink to={RouteURI.Transfers} icon={BiTransfer} />
      </>

      <>
        <SidebarCapital title="Manage" />
        <SidebarNavLink to={RouteURI.Members} icon={RiAdminLine} />
      </>

      <Spacer />
      <CollapseEx />
    </Stack>
  )
}


