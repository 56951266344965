import { TableRowProps, Tr, useColorModeValue } from "@chakra-ui/react";

export function CustomTr(props: ICustomTrProps & TableRowProps) {
  // 鼠标指向/选中的背景颜色
  const bgColor = useColorModeValue("gray.50", "gray.700");
  // 将isSelected提取出来，向Tr中传输rest props
  const { isSelected, ...restProps } = props;

  return (
    <Tr
      _hover={{ backgroundColor: bgColor, cursor: "pointer" }}
      bg={isSelected ? bgColor : ""}
      {...restProps}>
      {props.children}
    </Tr>
  );
}

interface ICustomTrProps {
  isSelected?: boolean;
}
