import { HStack, MenuItem, Spacer, Spinner, Text } from "@chakra-ui/react";
import { deleteMember } from "apis";
import dayjs from "dayjs";
import { useState } from "react";
import { FiDelete } from "react-icons/fi";
import { useSetRecoilState } from "recoil";
import { errorAtom, timestampAtom } from "state";
import { getErrorMessage } from "utils";

interface DeleteMemberMenuItemProps {
  id: number
}

export const DeleteMemberMenuItem = (props: DeleteMemberMenuItemProps) => {

  const setTimestamp = useSetRecoilState(timestampAtom)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const setError = useSetRecoilState(errorAtom)

  const _handleClick = async () => {
    setIsSubmitting(true)

    try {
      await deleteMember(props.id)
      setIsSubmitting(false)
      setTimestamp(dayjs().valueOf())
    } catch (error) {
      setIsSubmitting(false)
      setError(getErrorMessage(error))
    }
  };

  return (
    <MenuItem icon={<FiDelete />} color="red" onClick={_handleClick} closeOnSelect={false}>
      <HStack>
        <Text>Delete</Text>
        <Spacer />
        {isSubmitting && <Spinner size="sm" />}
      </HStack>
    </MenuItem>
  );
};
