import { PageParams, Resp, TimeRangeParams, Timestamps } from "./common";

export enum DeviceStatus {
  Enabled,
  Disabled,
}

export interface DevicesParams extends PageParams, TimeRangeParams {
  id?: number;
  imei?: string;
  status?: DeviceStatus[];
}

export type DevicesResp = Resp<Device[]>;

export interface Device extends Timestamps {
  id: number;
  imei: string;
  hardwareModel: string;
  osVersion: string;
  bundleId: string;
  appId: string;
  appBuild: 0;
  appVersion: string;
  fcmToken?: string;
  ip: string;
  status: 0;
}
