import {
  NullResp,
  UserDetailsResp,
  UsersParams,
  UsersResp,
  UserToBan,
  UserToEdit,
  UserToSubmit
} from "constant";
import { axiosInstance } from "./axios";
import { API_URI } from "./url";

export const fetchUsers = (params: UsersParams) => {
  return axiosInstance.post<UsersResp>(API_URI.USER_SEARCH, params);
};

export const fetchUserDetails = (uuid: string) => {
  return axiosInstance.get<UserDetailsResp>(API_URI.USER_DETAILS + "/" + uuid);
};

export const submitUser = (userToSubmit: UserToSubmit) => {
  return axiosInstance.post<NullResp>(API_URI.USER_SUBMIT, userToSubmit);
};

export const banUser = (userToBan: UserToBan) => {
  return axiosInstance.post<NullResp>(API_URI.USER_BAN, userToBan);
};

export const deleteUser = (uuid: string) => {
  return axiosInstance.post<NullResp>(API_URI.USER_DELETE, { uuid });
};

export const editUser = (userToEdit: UserToEdit) => {
  return axiosInstance.post<NullResp>(API_URI.USER_EDIT, userToEdit);
};
