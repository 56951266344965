import { Spacer, Stack, Table, TableCaption, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { Transfer } from "constant";
import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import { transfersQuery, transfersTotalAtom } from "state";
import { timestampMsToDateStr } from "utils";
import { CustomTr, HeaderStack, MonthFilter, Pagination, RefreshButton, TableSpinner, UserUUIDLink } from "views/components";

export function TransfersPage() {

  return (
    <Stack>
      <HeaderStack>
        <RefreshButton />
        <Pagination state={transfersTotalAtom} />

        <Spacer />
        <MonthFilter />
      </HeaderStack>

      <TransfersTable />
    </Stack >
  )
}

function TransfersTable() {
  return (

    <Table variant="simple">
      <TransfersTableHead />
      <Suspense fallback={<TableSpinner />}>
        <TransfersTableBody />
      </Suspense>
    </Table >
  )
}

function TransfersTableHead() {
  return (
    <Thead>
      <Tr>
        <Th>ID</Th>
        <Th>originalTransactionId</Th>
        <Th>From</Th>
        <Th>To</Th>
        <Th>Created At</Th>
        <Th>Updated At</Th>
      </Tr>
    </Thead>
  )
}

function TransfersTableBody() {
  const transfers = useRecoilValue(transfersQuery)

  return (
    <>
      {!transfers
        ? <TableCaption>No Results</TableCaption>
        : <Tbody>
          {transfers.map((transfer, index) => (
            <TransfersTableCell key={index} transfer={transfer} />
          ))}</Tbody>
      }
    </>
  )
}

interface TransfersTableCellProps {
  transfer: Transfer
}

function TransfersTableCell(props: TransfersTableCellProps) {
  const { transfer } = props
  return (
    <CustomTr>
      <Td>{transfer.id}</Td>
      <Td>{transfer.originalTransactionId}</Td>

      <Td>{transfer.fromUser &&
        <UserUUIDLink uuid={transfer.fromUser.uuid}>
          <Text>{transfer.fromUser.name}</Text>
        </UserUUIDLink>}
      </Td>

      <Td>{transfer.toUser &&
        <UserUUIDLink uuid={transfer.toUser.uuid}>
          <Text>{transfer.toUser.name}</Text>
        </UserUUIDLink>}
      </Td>

      <Td>{timestampMsToDateStr(transfer.createdAt)}</Td>
      <Td>{timestampMsToDateStr(transfer.updatedAt)}</Td>
    </CustomTr>
  )
}