import React from "react";
import { useRecoilState, useResetRecoilState } from "recoil";
import { pageAtom, statusAtom } from "state";
import { FilterOption, FilterSelector } from "./FilterSelector";

export function StatusFilter(props: StatusFilterProps) {
  const [status, setStatus] = useRecoilState(statusAtom)

  const resetPage = useResetRecoilState(pageAtom)
  const resetStatus = useResetRecoilState(statusAtom)

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event.target.value === "") {
      resetStatus()
    } else {
      setStatus(event.target.value)
      resetPage()
    }
  };

  return (
    <FilterSelector
      placeholder="Select Status"
      defaultValue={status}
      options={props.options}
      onChange={handleChange}
    />
  );
}
interface StatusFilterProps {
  options: FilterOption[];
}
