import { PageParams, Resp, Timestamps } from "./common";

export interface MemberToCreate {
  name: string;
  email: string;
}

export interface MemberToUpdate extends MemberToCreate {
  id: number;
  status: MemberStatus;
}

export type MembersResp = Resp<Member[]>;

export interface Member extends MemberToUpdate, Timestamps {
  account: string; // firebase uid
  avatar: string; // 头像url
}

export enum MemberRole {
  Viewer,
  Editor,
  Owner,
}

export enum MemberStatus {
  Invited = 0,
  Activated = 1,
  Disabled = 99,
}

// API Params
export interface MemberSearchParams extends PageParams {
  id?: number;
  email?: string;
  name?: string;
  status?: MemberStatus;
}
