import { Spinner, Wrap, WrapItem } from "@chakra-ui/react"
import { Suspense } from "react"
import { useRecoilValue } from "recoil"
import { photosAtom, selectedPhotoIdsAtom } from "state"
import { NextMediaView } from "views/components"

export function PhotosPage() {
  return (
    <Suspense fallback={<Spinner />}>
      <PhotosGrid />
    </Suspense>
  )
}

function PhotosGrid() {
  const photos = useRecoilValue(photosAtom)

  return (
    <Wrap>
      {photos && photos.map((mediaInfo, index) => (
        <WrapItem key={index}>
          <NextMediaView info={mediaInfo} selectedState={selectedPhotoIdsAtom} />
        </WrapItem>
      ))}
    </Wrap>
  )
}

