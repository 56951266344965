export enum API_URI {
  AVATAR_SEARCH = "/avatar/search",
  AVATAR_SUBMIT = "/avatar/review",

  MEDIA_SUBMIT = "/media/review",
  MEDIA_SEARCH = "/media/search",

  BIO_SEARCH = "/bio/search",
  BIO_SUBMIT = "/bio/review",

  DESCRIPTION_SEARCH = "/media/des/search",
  DESCRIPTION_SUBMIT = "/media/des/review",

  // reports
  REPORT_SEARCH = "/report/search",

  // users
  USER_SEARCH = "/user/search",
  USER_DETAILS = "/user/details/",
  USER_SUBMIT = "/user/review/details",
  USER_BAN = "/user/banned",
  USER_DELETE = "/user/delete",
  USER_EDIT = "/user/edit",

  // devices
  DEVICE_SEARCH = "/device/search",
  DEVICE_SWITCH_DISABLE = "/device/switch/disable",

  // purchase
  SUBSCRIPTION_SEARCH = "/iap/search",
  TRANSFER_SEARCH = "/iap/transfer/record/search",

  // member curd
  MEMBER_CREATE = "/admin/add",
  MEMBER_DELETE = "/admin/del",
  MEMBER_UPDATE = "/admin/edit",
  MEMBER_SEARCH = "/admin/search",
}
