import {
  AspectRatio,
  Avatar,
  Box,
  Center,
  HStack,
  Icon,
  Image,
  Progress,
  Spacer,
  Stack,
  Tag,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  AutoStatus,
  Media,
  MediaInfo,
  MediaStatus,
  MediaType,
  UserBasicInfo,
} from "constant";
import _ from "lodash";
import { TfiClose } from "react-icons/tfi";
import { useVideo } from "react-use";
import { RecoilState, useRecoilState } from "recoil";
import { getMediaTagColors } from "utils";
import { ZStack } from "./ZStack";
import { MediaFullScreenButton, UserUUIDLink } from "./id";

type NextMediaViewProps = {
  info: MediaInfo;
  selectedState: RecoilState<number[]>;
};

export function NextMediaView(props: NextMediaViewProps) {
  const _hoverBg = useColorModeValue("gray.100", "gray.600");
  const bg = useColorModeValue("gray.50", "gray.700");

  const [selects, select] = useRecoilState(props.selectedState);
  const isSelected = selects.includes(props.info.media.id);

  const isInvalid = [
    MediaStatus.Banned,
    MediaStatus.Deleted,
    MediaStatus.Rejected,
  ].includes(props.info.media.status);

  const handleClick = () => {
    console.log(props.info);
    if (isInvalid) return;

    select((curr) => {
      const _selects = _.cloneDeep(curr);

      if (isSelected) {
        _.remove(_selects, (id) => id === props.info.media.id);
      } else {
        _selects.push(props.info.media.id);
      }

      return _selects;
    });
  };

  return (
    <Stack
      w="221px"
      cursor="pointer"
      spacing={0}
      onClick={handleClick}
      bg={bg}
      _hover={{ bg: _hoverBg }}>
      {props.info.media.type === MediaType.Video ? (
        <NextVideoView
          video={props.info.media}
          isSelected={isSelected}
        />
      ) : (
        <NextImageView
          image={props.info.media}
          isSelected={isSelected}
        />
      )}

      <NextMediaInfoView
        media={props.info.media}
        user={{
          uuid: props.info.uuid,
          name: props.info.name,
          avatar: props.info.avatar,
        }}
      />
    </Stack>
  );
}

type NextImageViewProps = {
  image: Media;
  isSelected: boolean;
};

function NextImageView(props: NextImageViewProps) {
  const { image, isSelected } = props;

  return (
    <Box>
      <ZStack>
        <AspectRatio
          w="100%"
          h="100%"
          ratio={10 / 16}
          bg="black">
          <Image
            w="100%"
            h="100%"
            objectFit="cover"
            src={image.url}
          />
        </AspectRatio>

        <Stack
          h="100%"
          spacing={0}>
          <HStack p={2}>
            <MediaStatusTag status={image.status} />
            <NSFWTag
              autoStatus={image.autoStatus}
              riskScore={image.riskScore}
            />
          </HStack>

          <Spacer />
          <HStack p={2}>
            <Text opacity={1}>#{image.id}</Text>
            <Spacer />
          </HStack>
        </Stack>

        {isSelected && (
          <Center h="100%">
            <Icon
              as={TfiClose}
              w="100%"
              h="100%"
              color="red"
            />
          </Center>
        )}
      </ZStack>
    </Box>
  );
}

type NextVideoViewProps = {
  video: Media;
  isSelected: boolean;
};

function NextVideoView(props: NextVideoViewProps) {
  const { isSelected, video } = props;
  const [player, state, controls] = useVideo(<video src={video.url} />);

  return (
    <Box
      // onMouseEnter={() => console.log("mouse enter vide player")}
      onMouseEnter={controls.play}
      onMouseLeave={() => controls.pause()}>
      <ZStack h="100%">
        <AspectRatio
          w="100%"
          h="100%"
          ratio={10 / 16}
          bg="black">
          <Center h="100%">{player}</Center>
        </AspectRatio>

        <Stack
          h="100%"
          spacing={0}>
          <HStack p={2}>
            <MediaStatusTag status={video.status} />
            <NSFWTag
              autoStatus={video.autoStatus}
              riskScore={video.riskScore}
            />
          </HStack>

          <Spacer />

          <HStack p={2}>
            <Text opacity={1}>#{video.id}</Text>

            <Spacer />
            <Text opacity={0.4}>
              {Math.ceil(state.duration - state.time).toFixed(1)} s
            </Text>
          </HStack>

          <Progress
            value={(state.time / state.duration) * 100}
            size="xs"
          />
        </Stack>

        {isSelected && (
          <Center h="100%">
            <Icon
              as={TfiClose}
              w="100%"
              h="100%"
              color="red"
            />
          </Center>
        )}
      </ZStack>
    </Box>
  );
}

type NextMediaInfoViewProps = {
  media: Media;
  user: UserBasicInfo;
};

function NextMediaInfoView(props: NextMediaInfoViewProps) {
  return (
    <HStack
      p={2}
      justifyContent="space-between">
      <UserUUIDLink
        uuid={props.user.uuid}
        isExternal>
        <Avatar
          name={props.user.name}
          size="xs"
          src={props.user.avatar?.url}
        />
      </UserUUIDLink>
      <MediaFullScreenButton media={props.media} />
    </HStack>
  );
}

type MediaStatusTagProps = {
  status: MediaStatus;
};

function MediaStatusTag(props: MediaStatusTagProps) {
  const colors = getMediaTagColors(props.status);
  return (
    <Tag
      color={colors[0]}
      bg={colors[1]}>
      {MediaStatus[props.status]}
    </Tag>
  );
}
type NSFWTagProps = {
  autoStatus: AutoStatus;
  riskScore: number;
};

function NSFWTag(props: NSFWTagProps) {
  const rate = 1000000;

  const nsfwScore = props.riskScore / rate;
  const isNSFW = nsfwScore > 0.6 && nsfwScore !== 1.1;

  const getTagColor = () => {
    if (isNSFW) {
      return "red";
    } else if (nsfwScore < 0.1) {
      return "green";
    }
  };
  return (
    <Box>
      <Tag
        bg={getTagColor()}
        color="white">
        {isNSFW ? "NSFW" : nsfwScore}
      </Tag>
    </Box>
  );
}
