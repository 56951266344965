import { number, string } from "@recoiljs/refine";
import { Media, TimeRangeParams } from "constant";
import dayjs from "dayjs";
import { RecoilValueReadOnly, atom, selector, selectorFamily } from "recoil";
import { getRecoil, resetRecoil } from "recoil-nexus";
import { syncEffect } from "recoil-sync";
import { getTimeRangeParams } from "utils";
import { selectedAvatarIdsAtom } from "./avatars";
import { selectedBioIdsAtom } from "./bio";
import { photosRespQuery, selectedPhotoIdsAtom } from "./photos";
import { selectedVideoIdsAtom } from "./videos";

export const timestampAtom = atom<number>({
  key: "ts",
  default: dayjs().valueOf(),
  effects: [
    ({ onSet }) => {
      onSet(() => {
        // 刷新数据时，若已勾选，则重置之，避免selects列表过长
        if (getRecoil(selectedAvatarIdsAtom).length > 0) {
          resetRecoil(selectedAvatarIdsAtom);
        }

        if (getRecoil(selectedBioIdsAtom).length > 0) {
          resetRecoil(selectedBioIdsAtom);
        }

        if (getRecoil(selectedPhotoIdsAtom).length > 0) {
          resetRecoil(selectedPhotoIdsAtom);
        }

        if (getRecoil(selectedVideoIdsAtom).length > 0) {
          resetRecoil(selectedVideoIdsAtom);
        }
      });
    },
  ],
});

export const errorAtom = atom<string | undefined>({
  key: "error",
  default: undefined,
});

export const sidebarCollapseAtom = atom<boolean>({
  key: "sidebar/width",
  default: false,
});

export const fullscreenMediaAtom = atom<Media | undefined>({
  key: "fullscreenMedia",
  default: undefined,
});

export const nextStatusAtom = atom<number>({
  key: "status",
  default: 0,
  effects: [syncEffect({ refine: number() })],
});

export const lastIdAtom = atom({
  key: "lastId",
  default: "",
  effects: [syncEffect({ refine: string() })],
});

export const lastMediaIdQuery = selector({
  key: "media/lastId",
  get: ({ get }) => {
    const resp = get(photosRespQuery);
    return resp?.page;
  },
});

export const tabAtom = atom<number>({
  key: "t",
  default: 0,
  effects: [
    syncEffect({ refine: number() }),
    ({ onSet }) => {
      onSet(() => {
        // 重置page
        resetRecoil(pageAtom);
      });
    },
  ],
});

export const uuidAtom = atom<string>({
  key: "uuid",
  default: "",
  effects: [syncEffect({ refine: string() })],
});

export const imeiAtom = atom<string>({
  key: "imei",
  default: "",
  effects: [syncEffect({ refine: string() })],
});

export const nameAtom = atom<string>({
  key: "name",
  default: "",
  effects: [syncEffect({ refine: string() })],
});

export const statusAtom = atom<string>({
  key: "s",
  default: "",
  effects: [syncEffect({ refine: string() })],
});

export const monthAtom = atom<string>({
  key: "mon",
  default: "",
  effects: [syncEffect({ refine: string() })],
});

export const timeRangeQuery = selector<TimeRangeParams>({
  key: "timeRange",
  get: ({ get }) => {
    const month = get(monthAtom);
    return getTimeRangeParams(month);
  },
});

export const pageAtom = atom<number>({
  key: "p",
  default: 1,
  effects: [syncEffect({ refine: number() })],
});

// 从环境配置中获取默认分页大小
const defaultPageSize = parseInt(process.env.REACT_APP_PAGE_SIZE ?? "30");

export const pageSizeAtom = atom<number>({
  key: "pageSize",
  default: defaultPageSize,
  effects: [syncEffect({ refine: number() })],
});

export const totalPagesQuery = selectorFamily<
  number,
  RecoilValueReadOnly<number>
>({
  key: "totalPages",
  get:
    (totalSate) =>
    ({ get }) => {
      const total = get(totalSate);
      const pageSize = get(pageSizeAtom);

      return Math.ceil(total / pageSize);
    },
});
