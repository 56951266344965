import { Center, Heading, Link, Stack } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { RouteURI } from "routers";

export function NotFoundPage() {
  return (
    <Center h="100vh">
      <Stack>
        <Heading>Page Not Found</Heading>
        <Link as={RouterLink} to={{ pathname: RouteURI.Home }}>To Home</Link>
      </Stack>
    </Center>
  )
}