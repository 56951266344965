import { Identity } from "constant";
import { StatusColor } from "./StatusColor";
import { StatusTag } from "./StatusTag";

const colors: StatusColor[] = [
  { status: Identity.Fan, color: "red" },
  { status: Identity.Creator, color: "purple" },
];

interface IdentityTypeTagProps {
  identity: Identity;
}

// export 
export function IdentityTypeTag(props: IdentityTypeTagProps) {



  return (
    <StatusTag
      title={Identity[props.identity]}
      color={getIdentityColor(props.identity)}
    />
  );
}

export const getIdentityColor = (identity: Identity) => {
  return colors.find(color => color.status as Identity === identity)?.color;
};

export const getUserIdentity = (likeIdentity: Identity) => {
  return likeIdentity === Identity.Fan ? Identity.Creator : Identity.Fan
}