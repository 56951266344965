import { LocationState } from "constant";
import { Location } from "react-router-dom";
import { RouteURI } from "routers";

export function getLocationStateFrom(location: Location) {
  let from: string = RouteURI.Home;

  if (location.state !== null) {
    // 登录后需重定向的路径
    from = (location.state as LocationState).from.pathname;
  }

  return from;
}

function getLastSegmentFromPathname(pathname: string): string {
  const segments = pathname.split("/");
  const last = segments.pop() || segments.pop(); // Handle potential trailing slash
  return last ?? "";
}

export const getTitleFromPathname = (pathname: string) => {
  // 获取lastSegment，下划线使用空格代替
  const lastSegment = getLastSegmentFromPathname(pathname).replace(/_/g, " ");
  // 首字母大写
  return lastSegment
    .toLowerCase()
    .replace(/( |^)[a-z]/g, (L) => L.toUpperCase());
};
