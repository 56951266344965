import {
  ChakraProvider,
  extendTheme,
  useToast,
  withDefaultColorScheme,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { AppRoutes } from "routers";
import { errorAtom } from "state";
import { FullscreenMediaView } from "views/components";
import "./App.css";

const theme = extendTheme(
  {
    fonts: {
      body: "SofiaPro, sans-serif",
      heading: "SofiaPro, serif",
      mono: "SofiaPro, monospace",
    },
  },
  withDefaultColorScheme({ colorScheme: "purple" })
);

export const App = () => {
  const [error, setError] = useRecoilState(errorAtom);
  const toast = useToast();

  useEffect(() => {
    console.log(error);

    if (error) {
      toast({
        title: error,
        status: "error",
        onCloseComplete: () => setError(undefined),
      });
    }
  });

  return (
    <ChakraProvider theme={theme}>
      <AppRoutes />
      <FullscreenMediaView />
    </ChakraProvider>
  );
};
