import {
  Box,
  Divider,
  Heading,
  HeadingProps,
  HStack,
  SimpleGrid,
  Spacer,
  Spinner,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  Text,
  TextProps,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  DeviceStatus,
  Gender,
  Identity,
  Membership,
  UserStatus,
} from "constant";
import _ from "lodash";
import { Suspense, useEffect } from "react";
import { useParams } from "react-router";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { RouteURI } from "routers";
import { currentUUIDAtom, userDetailsQuery } from "state";
import { getDateFromTimestamp, timestampMsToAge } from "utils";
import {
  CopyButton,
  DeviceIMEILink,
  GenderEditor,
  HeaderStack,
  MoreButton,
  ReportsView,
  SubmitUserButton,
  UserAvatarView,
  UserBioView,
  UsernameWrapper,
  UserPhotosWrapper,
  UserVideosWrapper,
} from "views/components";

export function UserDetailsPage() {
  const params = useParams();
  const uuid = params["uuid"];

  const setCurrUUID = useSetRecoilState(currentUUIDAtom);

  useEffect(() => {
    setCurrUUID(uuid);

    return () => {
      setCurrUUID(undefined);
    };
  });

  return (
    <Stack>
      <Suspense fallback={<Spinner size="sm" />}>
        <UserDetailsPanel />
      </Suspense>
    </Stack>
  );
}

function UserDetailsPanel() {
  const user = useRecoilValue(userDetailsQuery);
  const basicInfoBg = useColorModeValue("gray.100", "gray.700");
  if (!user) return <></>;

  return (
    <HStack alignItems="flex-start">
      {/* Profile Basic Info */}
      <Stack
        position="sticky"
        top="4"
        w="375px"
        m="2"
        p="4"
        bg={basicInfoBg}
        borderRadius="14"
        spacing={4}>
        <UserAvatarView userInfo={user} />

        <Stack spacing={0}>
          <UsernameWrapper uuid={user.uuid}>
            <Heading size="lg">{user.name}</Heading>
          </UsernameWrapper>

          <Text opacity={0.6}>
            {[
              Gender[user.gender ?? 0],
              timestampMsToAge(user.birthday),
              user.extendInfo.city,
            ].join(", ")}
          </Text>
        </Stack>

        <Divider />
        <UserBioView bio={user.extendInfo.newBio} />

        <Divider />
        <Box opacity={0.4}>
          <Text>Joined at {getDateFromTimestamp(user.createdAt)}</Text>
        </Box>
      </Stack>

      {/* Profile Content */}
      <Stack w="100%">
        <HeaderStack>
          <Heading>Profile</Heading>
          <Tag
            bg="green"
            color="white">
            {user && UserStatus[user.status]}
          </Tag>
          <Spacer />

          <Suspense fallback={<></>}>
            <GenderEditor />
            <SubmitUserButton />
            <MoreButton />
          </Suspense>
        </HeaderStack>

        <Tabs isLazy>
          <TabList>
            <Tab>Photos</Tab>
            <Tab>Videos</Tab>

            {/* 举报 */}
            {user.reports && <Tab>Reports</Tab>}
            <Tab>Details</Tab>
          </TabList>
          <TabPanels>
            <TabPanel px={0}>
              <UserPhotosWrapper />
            </TabPanel>
            <TabPanel px={0}>
              <UserVideosWrapper />
            </TabPanel>

            {/* 举报 */}
            {user.reports && (
              <TabPanel px={0}>
                <ReportsView reports={user.reports} />
              </TabPanel>
            )}

            <TabPanel>
              <Stack
                spacing={8}
                pb={4}>
                <Stack>
                  <ProfileDetailCaption>Config</ProfileDetailCaption>
                  <SimpleGrid
                    columns={4}
                    gap={4}
                    spacing={4}>
                    <Stack>
                      <ProfileDetailHeading>Language</ProfileDetailHeading>
                      <ProfileDetailValue>
                        {user.config.language === ""
                          ? "EN"
                          : user.config.language}
                      </ProfileDetailValue>
                    </Stack>
                  </SimpleGrid>
                </Stack>

                <Stack>
                  <ProfileDetailCaption>Filters</ProfileDetailCaption>
                  <SimpleGrid
                    columns={4}
                    gap={4}
                    spacing={4}>
                    <Stack>
                      <ProfileDetailHeading>Looking For</ProfileDetailHeading>
                      <ProfileDetailValue>
                        {user.likeIdentity && Identity[user.likeIdentity]}
                      </ProfileDetailValue>
                    </Stack>

                    <Stack>
                      <ProfileDetailHeading>Like Genders</ProfileDetailHeading>
                      <ProfileDetailValue>
                        {_.sortBy(user.discoverFilter.likeGenders)
                          .map((gender) => Gender[gender])
                          .join(", ")}
                      </ProfileDetailValue>
                    </Stack>

                    <Stack>
                      <ProfileDetailHeading>Age Range</ProfileDetailHeading>
                      <ProfileDetailValue>
                        {user.discoverFilter.startAge} -{" "}
                        {user.discoverFilter.endAge}
                      </ProfileDetailValue>
                    </Stack>

                    <Stack>
                      <ProfileDetailHeading>Max Distance</ProfileDetailHeading>
                      <ProfileDetailValue>
                        {user.discoverFilter.maxDistance} mi
                      </ProfileDetailValue>
                    </Stack>
                  </SimpleGrid>
                </Stack>

                <Stack>
                  <ProfileDetailCaption>Links</ProfileDetailCaption>
                  <SimpleGrid
                    columns={4}
                    gap={4}
                    spacing={4}>
                    {/* LINKS */}
                    <Stack>
                      <ProfileDetailHeading>OnlyFans</ProfileDetailHeading>
                      <ProfileDetailValue>
                        {user.link?.onlyFans && "@" + user.link?.onlyFans}
                      </ProfileDetailValue>
                    </Stack>

                    <Stack>
                      <ProfileDetailHeading>Snapchat</ProfileDetailHeading>
                      <ProfileDetailValue>
                        {user.link?.snapchat && "@" + user.link?.snapchat}
                      </ProfileDetailValue>
                    </Stack>

                    <Stack>
                      <ProfileDetailHeading>Instagram</ProfileDetailHeading>
                      <ProfileDetailValue>
                        {user.link?.instagram && "@" + user.link?.instagram}
                      </ProfileDetailValue>
                    </Stack>

                    <Stack>
                      <ProfileDetailHeading>TikTok</ProfileDetailHeading>
                      <ProfileDetailValue>
                        {user.link?.tiktok && "@" + user.link?.tiktok}
                      </ProfileDetailValue>
                    </Stack>
                  </SimpleGrid>
                </Stack>

                <Stack>
                  <ProfileDetailCaption>Location</ProfileDetailCaption>
                  <SimpleGrid
                    columns={4}
                    gap={4}
                    spacing={4}>
                    <Stack>
                      <ProfileDetailHeading>Latitude</ProfileDetailHeading>
                      <ProfileDetailValue>
                        {user.extendInfo.lat}
                      </ProfileDetailValue>
                    </Stack>

                    <Stack>
                      <ProfileDetailHeading>Longitude</ProfileDetailHeading>
                      <ProfileDetailValue>
                        {user.extendInfo.lng}
                      </ProfileDetailValue>
                    </Stack>

                    <Stack>
                      <ProfileDetailHeading>City</ProfileDetailHeading>
                      <ProfileDetailValue>
                        {user.extendInfo.city}
                      </ProfileDetailValue>
                    </Stack>

                    <Stack>
                      <ProfileDetailHeading>Country</ProfileDetailHeading>
                      <ProfileDetailValue>
                        {user.extendInfo.country}
                      </ProfileDetailValue>
                    </Stack>

                    <Stack>
                      <ProfileDetailHeading>Register IP</ProfileDetailHeading>
                      <ProfileDetailValue>
                        {user.extendInfo.ip}
                      </ProfileDetailValue>
                    </Stack>
                  </SimpleGrid>
                </Stack>

                <Stack>
                  <ProfileDetailCaption>Purchase</ProfileDetailCaption>
                  <SimpleGrid
                    columns={4}
                    gap={4}
                    spacing={4}>
                    <Stack>
                      <ProfileDetailHeading>VIP</ProfileDetailHeading>
                      <ProfileDetailValue>
                        {Membership[user.lastSubscription.vip]}
                      </ProfileDetailValue>
                    </Stack>

                    <Stack>
                      <ProfileDetailHeading>Product</ProfileDetailHeading>
                      <ProfileDetailValue>
                        {[
                          user.lastSubscription.productName,
                          user.lastSubscription.productId,
                        ].join(" / ")}
                      </ProfileDetailValue>
                    </Stack>

                    <Stack>
                      <ProfileDetailHeading>Start At</ProfileDetailHeading>
                      <ProfileDetailValue>
                        {user.lastSubscription.startDate}
                      </ProfileDetailValue>
                    </Stack>

                    <Stack>
                      <ProfileDetailHeading>Expiry At</ProfileDetailHeading>
                      <ProfileDetailValue>
                        {user.lastSubscription.expiresDate}
                      </ProfileDetailValue>
                    </Stack>
                  </SimpleGrid>
                </Stack>

                <Stack>
                  <ProfileDetailCaption>Device</ProfileDetailCaption>
                  <SimpleGrid
                    columns={4}
                    gap={4}
                    spacing={4}>
                    <Stack>
                      <ProfileDetailHeading>IMEI</ProfileDetailHeading>
                      <DeviceIMEILink
                        imei={user.device.imei}
                        uri={RouteURI.Users}
                      />
                    </Stack>

                    <Stack>
                      <ProfileDetailHeading>IP</ProfileDetailHeading>
                      <ProfileDetailValue>{user.device.ip}</ProfileDetailValue>
                    </Stack>

                    <Stack>
                      <ProfileDetailHeading>Hardware</ProfileDetailHeading>
                      <ProfileDetailValue>
                        {user.device.hardwareModel}
                      </ProfileDetailValue>
                    </Stack>

                    <Stack>
                      <ProfileDetailHeading>App</ProfileDetailHeading>
                      <ProfileDetailValue>
                        {user.device.appVersion} ({user.device.appBuild})
                      </ProfileDetailValue>
                    </Stack>

                    <Stack>
                      <ProfileDetailHeading>App Client</ProfileDetailHeading>

                      <Stack spacing={0}>
                        <ProfileDetailValue>
                          {user.device.bundleId}
                        </ProfileDetailValue>
                        <ProfileDetailValue opacity={0.4}>
                          {user.device.appId}
                        </ProfileDetailValue>
                      </Stack>
                    </Stack>

                    <Stack>
                      <ProfileDetailHeading>Status</ProfileDetailHeading>
                      <ProfileDetailValue>
                        {DeviceStatus[user.device.status]}
                      </ProfileDetailValue>
                    </Stack>

                    <Stack>
                      <ProfileDetailHeading>FCM Token</ProfileDetailHeading>

                      {user.device.fcmToken ? (
                        <CopyButton value={user.device.fcmToken} />
                      ) : (
                        <ProfileDetailValue>--</ProfileDetailValue>
                      )}
                    </Stack>

                    <Stack>
                      <ProfileDetailHeading>Updated At</ProfileDetailHeading>
                      <ProfileDetailValue>
                        {getDateFromTimestamp(user.device.updatedAt)}
                      </ProfileDetailValue>
                    </Stack>
                  </SimpleGrid>
                </Stack>
              </Stack>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Stack>
    </HStack>
  );
}

function ProfileDetailCaption(props: HeadingProps) {
  return (
    <Heading
      as="h3"
      size="lg"
      {...props}
    />
  );
}

function ProfileDetailHeading(props: TextProps) {
  return (
    <Text
      opacity={0.5}
      fontSize="14"
      {...props}
    />
  );
}

function ProfileDetailValue(props: TextProps) {
  const { children, ...restProps } = props;
  return (
    <Text
      fontSize="18"
      {...restProps}>
      {children ? children : "--"}
    </Text>
  );
}
