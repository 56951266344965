import { fetchVideos } from "apis";
import {
  ContentToSubmit,
  MediaInfo,
  MediaStatus,
  MediaType,
  VideosResp,
} from "constant";
import { atom, selector } from "recoil";
import { setRecoil } from "recoil-nexus";
import { getErrorMessage } from "utils";
import { autoStatusQuery, statuses } from "views/components";
import { genders } from "views/layouts";
import {
  errorAtom,
  nextStatusAtom,
  pageAtom,
  pageSizeAtom,
  tabAtom,
  timestampAtom,
} from "./common";

export const videosRespQuery = selector<VideosResp | undefined>({
  key: "videos/resp",
  get: async ({ get }) => {
    // request id for refresh
    get(timestampAtom);

    const page = get(pageAtom);
    const pageSize = get(pageSizeAtom);
    const gender = genders[get(tabAtom)];
    const status = statuses[get(nextStatusAtom)];
    const autoStatus = get(autoStatusQuery);

    try {
      const resp = await fetchVideos({
        page,
        pageSize,
        gender: [gender],
        status: [status],
        type: [MediaType.Video],
        autoStatus,
      });
      return resp.data;
    } catch (error) {
      setRecoil(errorAtom, getErrorMessage(error));
      return undefined;
    }
  },
});

export const videosAtom = atom<MediaInfo[] | undefined>({
  key: "videos",
  default: selector({
    key: "videos/default",
    get: async ({ get }) => {
      const resp = get(videosRespQuery);

      if (resp?.error) {
        setRecoil(errorAtom, resp.error);
        return undefined;
      }

      if (resp?.data) {
        return resp.data;
      }

      return undefined;
    },
  }),
});

export const videosTotalQuery = selector({
  key: "videos/total",
  get: ({ get }) => {
    const resp = get(videosRespQuery);
    return resp?.page?.total ?? 0;
  },
});

export const selectedVideoIdsAtom = atom<number[]>({
  key: "videos/selected",
  default: [],
});

export const videosToSubmitQuery = selector<ContentToSubmit | undefined>({
  key: "videos/submit",
  get: ({ get }) => {
    const videos = get(videosAtom);
    const selectedIds = get(selectedVideoIdsAtom);

    if (!videos) return;

    let contentToSubmit: ContentToSubmit = {
      adoptIds: [],
      refuseIds: [],
    };

    videos.forEach((mediaInfo) => {
      const { media } = mediaInfo;

      if (selectedIds.includes(media.id)) {
        // 标记为reject
        contentToSubmit.refuseIds.push(media.id);
      } else if (media.status === MediaStatus.Pending) {
        // 新内容，待审核，默认通过
        contentToSubmit.adoptIds.push(media.id);
      }
    });

    return contentToSubmit;
  },
});
