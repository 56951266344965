import { IconButton, Link, LinkProps, Text } from "@chakra-ui/react";
import { Media } from "constant";
import { TfiFullscreen } from "react-icons/tfi";
import { useSetRecoilState } from "recoil";
import { fullscreenMediaAtom } from "state";

interface MediaIdLinkProps {
  f_media: Media

}

export function MediaIdLink(props: MediaIdLinkProps & LinkProps) {

  const { f_media, children, ...restProps } = props;
  const setFullscreenMedia = useSetRecoilState(fullscreenMediaAtom)

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.stopPropagation()
    console.log("view media in full screen, ", f_media)
    setFullscreenMedia(f_media)
  }

  return (
    <Link onClick={handleClick} {...restProps}>
      {!children ? <Text>#{f_media.id}</Text> : children}
    </Link>
  )
}

type MediaFullScreenButtonProps = {
  media: Media
}

export function MediaFullScreenButton(props: MediaFullScreenButtonProps) {

  const sendToModalView = useSetRecoilState(fullscreenMediaAtom)

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    sendToModalView(props.media)
  }

  return (
    <IconButton
      aria-label={"Full Screen"}
      icon={<TfiFullscreen />}
      variant="ghost"
      onClick={handleClick} />
  )
}