import { Heading, HStack, Spacer, Stack } from "@chakra-ui/react";
import { Gender } from "constant";
import { Suspense } from "react";
import { Outlet, useLocation } from "react-router";
import { RecoilValueReadOnly, useRecoilValue } from "recoil";
import { RouteURI } from "routers/routes";
import {
  avatarsTotalQuery,
  biosTotalQuery,
  photosTotalQuery,
  reportsTotalQuery,
  videosTotalQuery,
} from "state";
import { descriptionsTotalQuery } from "state/description";
import { getTitleFromPathname } from "utils";
import {
  AutoStatusSelect,
  CustomTab,
  HeaderStack,
  Pagination,
  StatusSelect,
  SubmitAvatarsButton,
  SubmitBiosButton,
  SubmitDescriptionsButton,
  SubmitPhotosButton,
  SubmitVideosButton,
} from "views/components";

export const genders = [
  Gender.Man,
  Gender.Woman,
  Gender.NonBinary,
  Gender.Unknown,
];

interface Option {
  pathname: RouteURI;
  totalState: RecoilValueReadOnly<number>;
  submitButton?: () => JSX.Element;
  trialingItems?: (() => JSX.Element)[];
}

const options: Option[] = [
  {
    pathname: RouteURI.Avatars,
    totalState: avatarsTotalQuery,
    trialingItems: [AutoStatusSelect, StatusSelect, SubmitAvatarsButton],
  },
  {
    pathname: RouteURI.Photos,
    totalState: photosTotalQuery,
    trialingItems: [AutoStatusSelect, StatusSelect, SubmitPhotosButton],
  },
  {
    pathname: RouteURI.Videos,
    totalState: videosTotalQuery,
    trialingItems: [AutoStatusSelect, StatusSelect, SubmitVideosButton],
  },
  {
    pathname: RouteURI.Bios,
    totalState: biosTotalQuery,
    trialingItems: [AutoStatusSelect, StatusSelect, SubmitBiosButton],
  },
  {
    pathname: RouteURI.Descriptions,
    totalState: descriptionsTotalQuery,
    trialingItems: [AutoStatusSelect, StatusSelect, SubmitDescriptionsButton],
  },
  {
    pathname: RouteURI.Reports,
    totalState: reportsTotalQuery,
  },
];

// MainLayout 主布局
export const ReviewLayout = () => {
  const location = useLocation();
  let option = options.find((opt) => opt.pathname === location.pathname);

  return (
    <Stack>
      <HeaderStack>
        <>
          <ReviewHeader />
          {option?.totalState && (
            <Suspense>
              <ReviewDataTotal state={option?.totalState} />
            </Suspense>
          )}
        </>
        <Spacer />

        {option?.trialingItems &&
          option.trialingItems.map((TrialingItem, index) => (
            <TrialingItem key={index} />
          ))}
      </HeaderStack>

      <CustomTab tabs={genders.map((g) => Gender[g].toString())} />

      <Outlet />

      <HStack>
        <Spacer />

        {option?.totalState && (
          <Suspense>
            <Pagination state={option?.totalState} />
          </Suspense>
        )}
      </HStack>
    </Stack>
  );
};

function ReviewHeader() {
  const location = useLocation();
  return <Heading>{getTitleFromPathname(location.pathname)}</Heading>;
}

type ReviewDataTotalProps = {
  state: RecoilValueReadOnly<number>;
};

function ReviewDataTotal(props: ReviewDataTotalProps) {
  const total = useRecoilValue(props.state);

  return <Heading>({total})</Heading>;
}
