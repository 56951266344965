import { BioStatus } from "constant";
import { StatusColor } from "./StatusColor";
import { StatusTag } from "./StatusTag";

const colors: StatusColor[] = [
  { status: BioStatus.Pending, color: "red" },
  { status: BioStatus.Approved, color: "green" },
  { status: BioStatus.Rejected, color: "gray" },
];

interface BioStatusTagProps {
  status: BioStatus;
}

// export 
export function BioStatusTag(props: BioStatusTagProps) {

  const getColor = () => {
    return colors.find(color => color.status as BioStatus === props.status)?.color;
  };

  return (
    <StatusTag
      title={BioStatus[props.status]}
      color={getColor()} />
  );
}