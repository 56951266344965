import { IconButton } from "@chakra-ui/react";
import _ from "lodash";
import { BsChevronCompactRight } from "react-icons/bs";
import { RecoilValueReadOnly, useRecoilValue, useSetRecoilState } from "recoil";
import { pageAtom, totalPagesQuery } from "state";

interface PaginationNextButtonProps {
  state: RecoilValueReadOnly<number>;
}

export function PaginationNextButton(props: PaginationNextButtonProps) {

  const setPage = useSetRecoilState(pageAtom);
  const totalPages = useRecoilValue(totalPagesQuery(props.state));

  const handleClickNext = () => {

    setPage(curr => {
      const _page = _.cloneDeep(curr);
      return Math.min(_page + 1, totalPages);
    });
  };

  return (
    <IconButton
      aria-label="Next"
      icon={<BsChevronCompactRight />}
      size="sm"
      variant="outline"
      onClick={handleClickNext}
    />
  );
}

