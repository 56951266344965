import { Spacer, Stack, Table, TableCaption, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { PurchaseFrom, Subscription } from "constant";
import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import { subscriptionsQuery, subscriptionsTotalAtom } from "state";
import { timestampMsToDateStr } from "utils";
import { CustomTr, HeaderStack, MonthFilter, Pagination, RefreshButton, TableSpinner, UserUUIDLink } from "views/components";

export function SubscriptionsPage() {

  return (
    <Stack>
      <HeaderStack>
        <RefreshButton />
        <Pagination state={subscriptionsTotalAtom} />

        <Spacer />
        <MonthFilter />
      </HeaderStack>

      <SubscriptionsTable />
    </Stack >
  )
}

function SubscriptionsTable() {
  return (

    <Table variant="simple">
      <SubscriptionsTableHead />
      <Suspense fallback={<TableSpinner />}>
        <SubscriptionsTableBody />
      </Suspense>
    </Table >
  )
}

function SubscriptionsTableHead() {
  return (
    <Thead>
      <Tr>
        <Th>ID</Th>
        <Th>From</Th>
        <Th>Package</Th>

        <Th>Original Purchase Date</Th>
        <Th>Original Transaction Id</Th>
        <Th>Purchase Date</Th>
        <Th>Transaction Id</Th>

        <Th>Expires Date</Th>

        <Th>User</Th>

        <Th>Created At</Th>
        <Th>Updated At</Th>
      </Tr>
    </Thead>
  )
}

function SubscriptionsTableBody() {
  const subscriptions = useRecoilValue(subscriptionsQuery)

  return (
    <>
      {!subscriptions
        ? <TableCaption>No Results</TableCaption>
        : <Tbody>
          {subscriptions.map((subscription, index) => (
            <SubscriptionsTableCell key={index} subscription={subscription} />
          ))}</Tbody>
      }
    </>
  )
}

interface SubscriptionsTableCellProps {
  subscription: Subscription
}

function SubscriptionsTableCell(props: SubscriptionsTableCellProps) {
  const { subscription } = props
  return (
    <CustomTr>
      <Td>{subscription.id}</Td>
      <Td>{PurchaseFrom[subscription.purchaseFrom]}</Td>
      <Td>{subscription.productId}</Td>

      <Td>{timestampMsToDateStr(subscription.originalPurchaseDate)}</Td>
      <Td>{subscription.originalTransactionId}</Td>

      <Td>{timestampMsToDateStr(subscription.purchaseDate)}</Td>
      <Td>{subscription.transactionId}</Td>

      <Td>{timestampMsToDateStr(subscription.expiresDate)}</Td>

      <Td>
        <UserUUIDLink uuid={subscription.uuid}>
          <Text>{subscription.name}</Text>
        </UserUUIDLink>
      </Td>

      <Td>{timestampMsToDateStr(subscription.createdAt)}</Td>
      <Td>{timestampMsToDateStr(subscription.updatedAt)}</Td>
    </CustomTr>
  )
}