import {
  Box,
  Button,
  Center,
  Heading,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { useEffect, useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { signedInUserAtom } from "state";
import { getErrorMessage, getLocationStateFrom } from "utils";
import { auth } from "../../firebase";

export const LoginPage = () => {
  const [loading, setLoading] = useState(false);
  const signedInUser = useRecoilValue(signedInUserAtom);

  const location = useLocation();
  const toast = useToast();
  const navigate = useNavigate();

  const handleSignInWithGoogle = async () => {
    setLoading(true);

    try {
      let provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: getErrorMessage(error),
        status: "error",
      });
    }
  };

  useEffect(() => {
    if (signedInUser) {
      navigate(getLocationStateFrom(location), { replace: true });
    }
  });

  return (
    <Center h="50vh">
      <Stack alignItems="center">
        <Heading>TrueFans</Heading>
        <Box>
          <Button
            colorScheme="gray"
            leftIcon={<FcGoogle />}
            onClick={handleSignInWithGoogle}
            isLoading={loading}>
            Continue with Google
          </Button>
        </Box>
      </Stack>
    </Center>
  );
};
