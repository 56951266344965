import { fetchBios } from "apis";
import { Bio, BiosResp, BioStatus, ContentToSubmit } from "constant";
import { atom, selector } from "recoil";
import { setRecoil } from "recoil-nexus";
import { getErrorMessage } from "utils";
import { autoStatusQuery, statuses } from "views/components";
import { genders } from "views/layouts";
import {
  errorAtom,
  nextStatusAtom,
  pageAtom,
  pageSizeAtom,
  tabAtom,
  timestampAtom,
} from "./common";

// BIO
export const biosRespQuery = selector<BiosResp | undefined>({
  key: "bios/resp",
  get: async ({ get }) => {
    // request id for refresh
    get(timestampAtom);

    const page = get(pageAtom);
    const pageSize = get(pageSizeAtom);
    const gender = genders[get(tabAtom)];
    const status = statuses[get(nextStatusAtom)];
    const autoStatus = get(autoStatusQuery);

    try {
      const resp = await fetchBios({
        page,
        pageSize,
        gender: [gender],
        status: [status],
        autoStatus,
      });
      return resp.data;
    } catch (error) {
      setRecoil(errorAtom, getErrorMessage(error));
      return undefined;
    }
  },
});

export const biosAtom = selector<Bio[] | undefined>({
  key: "bios",
  get: async ({ get }) => {
    const resp = get(biosRespQuery);

    if (resp?.error) {
      setRecoil(errorAtom, resp.error);
      return undefined;
    }

    if (resp?.data) {
      return resp.data;
    }

    return undefined;
  },
});

export const biosTotalQuery = selector({
  key: "bios/total",
  get: ({ get }) => {
    const resp = get(biosRespQuery);
    return resp?.page?.total ?? 0;
  },
});

export const selectedBioIdsAtom = atom<number[]>({
  key: "selected/bio_ids",
  default: [],
});

export const bioToSubmitQuery = selector<ContentToSubmit | undefined>({
  key: "bioToSubmit",
  get: ({ get }) => {
    const bios = get(biosAtom);
    const selectedIds = get(selectedBioIdsAtom);

    if (!bios) return;

    let contentToSubmit: ContentToSubmit = {
      adoptIds: [],
      refuseIds: [],
    };

    bios.forEach((item) => {
      if (selectedIds.includes(item.id)) {
        // 标记为reject
        contentToSubmit.refuseIds.push(item.id);
      } else if (item.status === BioStatus.Pending) {
        // 新内容，待审核，默认通过
        contentToSubmit.adoptIds.push(item.id);
      }
    });

    return contentToSubmit;
  },
});
