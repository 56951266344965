import { number } from "@recoiljs/refine";
import { onAuthStateChanged, User } from "firebase/auth";
import { atom } from "recoil";
import { setRecoil } from "recoil-nexus";
import { syncEffect } from "recoil-sync";
import { getErrorMessage } from "utils";
import { auth } from "../firebase";
import { errorAtom } from "./common";

// signedInUserAtom 已登录的用户
// 未登录为null
// 未初始化为undefined
export const signedInUserAtom = atom<User | null | undefined>({
  key: "signedInuUer",
  default: undefined,
  effects: [
    // firebase auth
    ({ setSelf }) => {
      onAuthStateChanged(
        auth,
        async (user) => {
          // 未登录
          if (!user) {
            setSelf(null);
            return;
          }

          // 获取角色权限
          const result = await user?.getIdTokenResult();
          const { role } = result?.claims;

          // 无权限
          if (!role || !(role as string[]).includes("onlyface")) {
            setRecoil(errorAtom, "missing permission, role: " + role);
            auth.signOut();
            return;
          }

          // 有权限
          console.log("auth success, uid:", user.uid);

          // recoil会直接修改user对象，将其变更为只读，使用以下方式修复
          // 参照：https://github.com/firebase/firebase-js-sdk/issues/5722
          const _user = JSON.parse(JSON.stringify(user)) as User;
          setSelf(_user);
        },
        (error) => {
          console.log("auth failed: ", getErrorMessage(error));
          setSelf(null);
        }
      );
    },
  ],
});

export const retriesCountAtom = atom<number>({
  key: "rt_ct",
  default: 0,
  effects: [syncEffect({ refine: number() })],
});
