import { Box, LinkProps, Text } from "@chakra-ui/react";
import { keyframes } from "@emotion/react";
import { UserBasicInfo } from "constant";
import { UserUUIDLink } from "./id";

interface UsernameProps {
  userInfo: UserBasicInfo;
}

export function Username(props: UsernameProps) {
  const { userInfo } = props;

  const loop = keyframes`
      0% {
        margin-left: 0;
        transform: translateX(0);
      }
      25% {
        margin-left: 0;
        transform: translateX(0);
      }
      75% {
        margin-left: 100%;
        transform: translateX(-100%);
      }
      100% {
        margin-left: 100%;
        transform: translateX(-100%);
      }
      `;

  return (
    <Box
      maxW="100%"
      display="inline-block"
      overflow="hidden"
      whiteSpace="nowrap">
      <UserUUIDLink
        isExternal
        uuid={userInfo.uuid}
        animation={loop + " linear 4s alternate infinite"}
        float="left">
        {userInfo.name && <Text>{userInfo.name}</Text>}
      </UserUUIDLink>
    </Box>
  );
}

type UsernameWrapperProps = {
  name?: string;
  uuid: string;
};

export function UsernameWrapper(props: UsernameWrapperProps & LinkProps) {
  const loop = keyframes`
      0% {
        margin-left: 0;
        transform: translateX(0);
      }
      25% {
        margin-left: 0;
        transform: translateX(0);
      }
      75% {
        margin-left: 100%;
        transform: translateX(-100%);
      }
      100% {
        margin-left: 100%;
        transform: translateX(-100%);
      }
      `;

  const { uuid, name, children, ...linkProps } = props;

  return (
    <Box>
      <Box
        maxW="100%"
        display="inline-block"
        overflow="hidden"
        whiteSpace="nowrap">
        <UserUUIDLink
          isExternal
          uuid={props.uuid}
          animation={loop + " linear 4s alternate infinite"}
          float="left"
          {...linkProps}>
          {children ? children : name && <Text>{props.name}</Text>}
        </UserUUIDLink>
      </Box>
    </Box>
  );
}
