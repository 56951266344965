import { Avatar, Box, Center, HStack, Icon, Tag } from "@chakra-ui/react";
import { MediaStatus, UserBasicInfo } from "constant";
import _ from "lodash";
import { TfiClose } from "react-icons/tfi";
import { useRecoilState } from "recoil";
import { selectedAvatarIdsAtom } from "state";
import { ZStack } from "views/components";

export function UserAvatarView({ userInfo }: UserAvatarViewProps) {
  const [selectedAvatarIds, select] = useRecoilState(selectedAvatarIdsAtom);

  const { avatar } = userInfo;

  if (!avatar) return;

  const isSelected = selectedAvatarIds.includes(avatar.id);

  const handleClick = () => {
    select((curr) => {
      const _selectedIds = _.cloneDeep(curr);

      if (isSelected) {
        _.remove(_selectedIds, (id) => id === avatar.id);
      } else {
        _selectedIds.push(avatar.id);
      }

      return _selectedIds;
    });
  };

  return (
    <HStack alignItems="flex-end">
      <Box>
        <ZStack
          onClick={handleClick}
          cursor="pointer">
          <Avatar
            name={userInfo.name}
            src={userInfo.avatar?.url}
            size="2xl"
          />
          {isSelected && (
            <Center
              h="100%"
              p={4}>
              <Icon
                as={TfiClose}
                w="100%"
                h="100%"
                color="red"
              />
            </Center>
          )}
        </ZStack>
      </Box>

      <Tag>{MediaStatus[avatar.status]}</Tag>
    </HStack>
  );
}
interface UserAvatarViewProps {
  userInfo: UserBasicInfo;
}
