import { Avatar, HStack, Link, LinkProps, Text } from "@chakra-ui/react";
import { Media } from "constant";
import { Link as RRDLink } from "react-router-dom";
import { RouteURI } from "routers";

interface UserUUIDProps {
  uuid: string;
}

export function UserUUIDLink(props: UserUUIDProps & LinkProps) {
  const { uuid, children, ...restProps } = props;

  return (
    <Link
      as={RRDLink}
      to={`${RouteURI.Users}/${uuid}`}
      onClick={e => e.stopPropagation()}
      {...restProps}
    >
      {!children ? <Text>:{uuid.slice(-8)}</Text> : children}
    </Link>
  )
}

interface UserLinkProps {
  uuid: string,
  name?: string,
  avatar?: Media;
}

export function UserLink(props: UserLinkProps & LinkProps) {
  const { uuid, name, avatar, ...linkProps } = props

  return (
    <HStack>
      <UserUUIDLink uuid={uuid} {...linkProps}>
        <Avatar size="sm" name={name} src={avatar?.url} />
      </UserUUIDLink>
      <UserUUIDLink uuid={uuid} {...linkProps}>
        <Text whiteSpace="nowrap">{name}</Text>
      </UserUUIDLink>
    </HStack>
  )
}