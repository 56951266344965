import {
  Spacer, Stack
} from "@chakra-ui/react"
import { membersTotalQuery } from "state"
import { AddMemberButton, HeaderStack, Pagination, RefreshButton } from "views/components"
import { MembersTable } from "../components/member/MembersTable"

export function MembersPage() {

  return (
    <Stack>
      <HeaderStack>
        <RefreshButton />
        <Pagination state={membersTotalQuery} />

        <Spacer />
        <AddMemberButton />
      </HeaderStack>

      {/* table */}
      <MembersTable />

    </Stack>
  )
}




