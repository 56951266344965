import { Box, IconButton, Tooltip } from "@chakra-ui/react";
import dayjs from "dayjs";
import { FiRefreshCcw } from "react-icons/fi";
import { useSetRecoilState } from "recoil";
import { timestampAtom } from "state";

export function RefreshButton() {

  const setTimestamp = useSetRecoilState(timestampAtom);
  const handleClick = () => {
    setTimestamp(dayjs().valueOf());
  };

  return (
    <Box>
      <Tooltip label="Refresh">
        <IconButton
          aria-label="Refresh"
          icon={<FiRefreshCcw />}
          variant="ghost"
          onClick={handleClick} />
      </Tooltip>
    </Box>
  );
}
