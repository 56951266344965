
import { Center, Spinner } from "@chakra-ui/react"
import { LocationState } from "constant"
import { Navigate, useLocation } from "react-router-dom"
import { useRecoilValue } from "recoil"
import { signedInUserAtom } from "state"
import { RouteURI } from "./routes"

export const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const location = useLocation()
  const signedInUser = useRecoilValue(signedInUserAtom)

  // 未完成firebase auth初始化
  if (signedInUser === undefined) {
    return <Center h="100vh"><Spinner /></Center>
  }

  // 用户未登录
  if (signedInUser === null) {

    // DEBUG 模式，跳过auth要求
    if (process.env.REACT_APP_DEBUG) {
      return children;
    }

    // 重定向至Login Page, 设置from state
    const state: LocationState = { from: location }
    return <Navigate to={RouteURI.Login} state={{ from: state }} />
  }

  return children
}