import { UserStatus } from "constant";
import { StatusColor } from "./StatusColor";
import { StatusTag } from "./StatusTag";

const colors: StatusColor[] = [
  { status: UserStatus.New, color: "yellow" },
  { status: UserStatus.Pending, color: "red" },
  { status: UserStatus.Approved, color: "green" },
  { status: UserStatus.Rejected, color: "gray" },
  { status: UserStatus.Banned, color: "gray" },
  { status: UserStatus.Deleted, color: "gray" },
];

interface UserStatusTagProps {
  status: UserStatus;
}

// export 
export function UserStatusTag(props: UserStatusTagProps) {

  const getColor = () => {
    return colors.find(color => color.status as UserStatus === props.status)?.color;
  };

  return (
    <StatusTag
      title={UserStatus[props.status]}
      color={getColor()}
      isDeleted={props.status === (UserStatus.Deleted)} />
  );
}